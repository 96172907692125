<template>
  <div class="container">
    <div class="maingbg">
      <a href="/"> <div class="logo"></div></a>
      <div class="hamburger">
        <a href="#" @click.prevent="isOpenM">
          <img class="hamburger" src="../assets/hamburgermenu.png" alt=""
        /></a>
      </div>
      <div class="menu" v-if="isOpen">
        <a href="#" @click.prevent="isOpenM">
          <img class="cruze" src="../assets/cruze.png" alt=""
        /></a>
        <img class="logomenu" src="../assets/logomenu.png" alt="" />
        <ul>
          <li>
            <router-link to="/history" exact active-class="active"
              >ИСТОРИЯ</router-link
            >
          </li>
          <li>
            <router-link to="/activity" active-class="active"
              >ДЕЯТЕЛЬНОСТЬ</router-link
            >
          </li>
          <li>
            <router-link to="/vacancies" active-class="active"
              >ВАКАНСИИ</router-link
            >
          </li>
          <li>
            <router-link to="/students" active-class="active"
              >СТУДЕНТАМ</router-link
            >
          </li>
          <li>
            <router-link to="/partners" active-class="active"
              >ПАРТНЕРАМ</router-link
            >
          </li>
          <li>
            <router-link to="/contacts" active-class="active"
              >КОНТАКТЫ</router-link
            >
          </li>
          <li>
            <router-link to="/news" active-class="active">НОВОСТИ</router-link>
          </li>
        </ul>

        <div class="socialblack">
          <a href="https://vk.com/zaryaputino" target="_blank" rel="vk"
            ><img class="left" src="../assets/vkblack.png" alt="" width="30"
          /></a>
        </div>
      </div>
      <div class="title">
        <div class="maintitle">ВАКАНСИИ</div>
      </div>
    </div>
  </div>
  <div class="containersearch">
    <div class="title">МЫ ИЩЕМ ТЕБЯ</div>
    <img src="../assets/vacancies/redromb.png" alt="" class="redromb" />
    <div class="spisok">
      <ul>
        <li v-for="(vac, index) in vacancies_list" :key="vac">
          <router-link to="#">{{ vac }}</router-link>
        </li>
        <!-- <li><router-link to="#">Электрик</router-link></li>
        <li><router-link to="#">Животновод</router-link></li>
        <li>
          <router-link to="#">Кладовщик</router-link>
        </li>
        <li>
          <router-link to="#"
            >Начальник животноводческого комплекса</router-link
          >
        </li>
        <li><router-link to="#">Оператор машинного доения</router-link></li>
        <li>
          <router-link to="#">Телятница</router-link>
        </li>
        <li>
          <router-link to="#">Техник-осеменатор </router-link>
        </li>
        <li>
          <router-link to="#"> Сервисный инженер </router-link>
        </li>
        <li>
          <router-link to="#">
            Ветеринарный санитар по обработке и лечению копыт
          </router-link>
        </li>
        <li>
          <router-link to="#"> Тракторист </router-link>
        </li>
        <li>
          <router-link to="#"> Агроном </router-link>
        </li> -->
      </ul>
    </div>
  </div>
  <div class="containerform">
    <div class="uno">
      <div class="title">Не нашли подходящую вакансию?</div>
      <div class="subtitle">
        Оставьте свои данные и мы обязательно свяжемся с вами, если появится
        что-то подходящее.
      </div>
      <form class="form" @submit.prevent="sendEmailJS">
        <input
          class="input1"
          type="text"
          placeholder="ФИО"
          name="to_name"
          v-model="fio"
        />

        <input
          class="input2"
          type="text"
          placeholder="Желаемая должность"
          name="from_name"
          v-model="dolznost"
        />

        <textarea
          class="textarea1"
          placeholder="О себе"
          name="message"
          v-model="osebe"
        ></textarea>
        <div v-if="otpravleno == true" class="otpravleno">
          Мы рассмотрим вашу кандитатуру в ближайшее время и свяжемся с вами!
        </div>
        <input class="btn" type="submit" value="ОТПРАВИТЬ" />
      </form>
    </div>
    <div class="dos">
      <!-- <img src="../assets/vacancies/redromb2.png" alt="" class="redromb" /> -->
      <img src="../assets/vacancies/img1.png" alt="" class="img1" />
      <img src="../assets/vacancies/img2.png" alt="" class="img2" />
      <!-- <img src="../assets/vacancies/img3.png" alt="" class="img3" />
      <img src="../assets/vacancies/blackromb.png" alt="" class="blackromb" /> -->
    </div>
  </div>
  <footer-site></footer-site>
</template>
<script>
import MenuTop from "../components/MenuTop.vue";
import FooterSite from "../components/Footer.vue";
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      fio: "",
      dolznost: "",
      osebe: "",
      otpravleno: false,
      isOpen: false,
      vacancies_list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetch("https://cb96744.tmweb.ru/api/articles/");
      const finalRes = await res.json();
      this.vacancies_list = finalRes[22].text.split(";");
    },
    previewfile(event) {
      this.fileData = event.target.files[0];
    },
    sendEmailJS(e) {
      emailjs
        .sendForm(
          "zaryaputino",
          "template_l0pzak7",
          e.target,
          "user_r6zLonxJT1IJltE5wzNWp"
        )
        .then(
          (result) => {
            console.log("SUCCESS!", result.status, result.text);
          },
          (error) => {
            console.log("FAILED...", error);
          }
        );
      this.fio = "";
      this.dolznost = "";
      this.osebe = "";
      this.otpravleno = true;
    },
    isOpenM() {
      if (this.isOpen == true) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
    },
  },
  components: {
    MenuTop,
    FooterSite,
  },
};
</script>
<style scoped>
.container {
  position: relative;
  height: 100%;
}

.container .maingbg {
  margin: 0 auto;
  background: url("../assets/vacancies/vacanciesbg.png");
  max-width: 414px;
  width: 100%;
  height: 386px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.container .maingbg .logo {
  background-image: url("../assets/logo.png");
  position: relative;
  width: 197px;
  height: 37px;
  left: 90px;
  top: 47px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.openmenu {
  visibility: visible;
  display: block;
  position: absolute;
  width: 375px;
  height: 583px;
  top: 0px;
  background-color: white;
}
.cruze {
  position: absolute;
  top: 17px;
  right: 17px;
  width: 16.67px;
  z-index: 100;
}
.logomenu {
  position: absolute;
  top: 40px;
  width: 272px;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.menu {
  display: block;
  position: absolute;
  max-width: 414px;
  z-index: 100;
  width: 100%;
  height: 583px;
  top: 0px;
  background-color: white;
}
.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  top: 142px;
}
.menu li {
  /* float: left; */
  font-family: RFD-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 17px; */
}
.menu li a {
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.menu li a:hover {
  color: #ff4b00;
}
.clickGeneral {
  position: absolute;
  top: -115px;
  left: 5px;
  width: 200px;
  height: 40px;
}
.menu .router-link-exact-active {
  color: #ff4b00;
}
.container .maingbg .socialblack {
  position: absolute;
  left: 155px;
  top: 450px;
  width: 90px;
  z-index: 101;
  height: 32px;
}
.container .maingbg .socialblack .left {
  float: left;
  position: absolute;
  bottom: 2px;
}
.container .maingbg .socialblack .right {
  float: right;
  position: absolute;
  bottom: 0px;
  left: 62px;
}
.container .maingbg .title {
  display: block;
  position: relative;
  left: 31px;
  top: 240px;
  width: 332px;
  font-family: RFD-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 17px;
}
.container .maingbg .title .maintitle {
  font-size: 30px;
  line-height: 84px;
  color: #ffffff;
}
.container .maingbg .title .secondtitle {
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
}
.containersearch {
  position: relative;
  width: 375px;
  height: 800px;
  margin: 0 auto;
  margin-top: 50px;
}
.containersearch .title {
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
}
.containersearch .redromb {
  width: 375px;
  height: 57px;
  position: absolute;
  top: 720px;
  left: 0px;
}
.containersearch .spisok ul {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
}
.containersearch .spisok ul li {
  list-style-type: none;
  margin: 20px 0px;
}
.containersearch .spisok ul li a {
  color: #271811;
}
.containerform {
  position: relative;
  width: 375px;
  height: 1050px;
  margin: 0 auto;
}
.containerform .uno {
  width: 375px;
  /* margin-left: 40px;
  float: left;
  margin-top: 110px; */
}
.containerform .uno .title {
  font-family: RFD-Bold;
  font-size: 16px;
  position: relative;
  text-align: center;
  width: 281px;
  left: 47px;
  margin: 0px 0;
}
.containerform .uno .subtitle {
  font-family: RFD-Light;
  font-size: 16px;
  width: 342px;
  position: relative;
  left: 16px;
  top: 15px;
  text-align: center;
}
.containerform .uno .form {
  margin-top: 20px;
}
.containerform .uno .form .input1 {
  width: 344px;
  margin: 20px 0;
  height: 60px;
  font-family: RFD-Regular;
  font-size: 16px;
  padding-left: 30px;
}
.containerform .uno .form .input2 {
  width: 344px;
  height: 60px;
  font-family: RFD-Regular;
  font-size: 16px;
  padding-left: 30px;
}
.containerform .uno .form .textarea1 {
  width: 344px;
  margin: 20px 0;
  height: 297px;
  resize: none;
  font-family: RFD-Regular;
  font-size: 16px;
  padding: 20px 0 0 33px;
}
.containerform .uno .form .btn {
  width: 271px;
  height: 60px;
  background-color: #ff4b00;
  color: white;
  box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: RFD-Regular;
  font-size: 16px;
  border: 0px;
  position: relative;
  left: 52px;
}
.containerform .uno .form .otpravleno {
  font-family: RFD-Regular;
  font-size: 14px;
  margin: 0px 0px 20px 0px;
}
.containerform .dos {
  width: 375px;
  position: relative;
  top: 60px;
  /* margin-left: 61px;
  float: left; */
}
.containerform .dos .redromb {
  width: 340px;
  position: absolute;
  right: 334px;
}
.containerform .dos .img1 {
  width: 198px;
  position: absolute;
  right: 15px;
  top: 135px;
  z-index: 1;
  border-radius: 5px;
}
.containerform .dos .img2 {
  width: 263px;
  position: absolute;
  left: 15px;
  top: 0px;
  border-radius: 5px;
}
.containerform .dos .img3 {
  width: 404px;
  position: absolute;
  right: 230px;
  top: 636px;
}
.containerform .dos .blackromb {
  width: 340px;
  position: absolute;
  right: 190px;
  top: 733px;
  z-index: -1;
}
</style>

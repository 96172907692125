<template>
  <div class="containerfooter">
    <div class="footer">
      <div class="bg">
        <img src="../assets/footer.png" alt="" />
      </div>
      <div class="cowfooter">
        <img src="../assets/cowfooter.png" alt="" />
      </div>
      <div class="logo">
        <img src="../assets/logofooter.png" alt="" />
      </div>
      <div class="menu">
        <ul>
          <li>
            <router-link to="/history" exact active-class="active"
              >ИСТОРИЯ</router-link
            >
          </li>
          <li>
            <router-link to="/activity" active-class="active"
              >ДЕЯТЕЛЬНОСТЬ</router-link
            >
          </li>
          <li>
            <router-link to="/vacancies" active-class="active"
              >ВАКАНСИИ</router-link
            >
          </li>
          <li>
            <router-link to="/students" active-class="active"
              >СТУДЕНТАМ</router-link
            >
          </li>
          <li>
            <router-link to="/partners" active-class="active"
              >ПАРТНЕРАМ</router-link
            >
          </li>
          <li>
            <router-link to="/contacts" active-class="active"
              >КОНТАКТЫ</router-link
            >
          </li>
          <li>
            <router-link to="/news" active-class="active">НОВОСТИ</router-link>
          </li>
        </ul>
      </div>
      <div class="footersocial">
        <div class="social">
          <a href="https://vk.com/zaryaputino" target="_blank" rel="vk"
            ><img src="../assets/vkblack.png" alt=""
          /></a>
        </div>
        <div class="pc"><a href="/">Политика конфиденциальности</a></div>
        <div class="copy">© 2021 · Все права защищены</div>
      </div>
    </div>
  </div>
</template>
<style>
@media only screen and (max-width: 479px) {
  .containerfooter {
    position: relative;
  }
  .containerfooter .footer {
    position: relative;
    margin: 0 auto;
    width: 375px;
    height: 770px;
  }
  .containerfooter .footer .bg img {
    width: 375px;
  }
  .containerfooter .footer .cowfooter {
    position: absolute;
    top: 35px;
  }
  .containerfooter .footer .cowfooter img {
    width: 375px;
  }
  .containerfooter .footer .logo {
    position: absolute;
    top: 320px;
    left: 52px;
  }
  .containerfooter .footer .logo img {
    width: 272px;
  }
  .containerfooter .footer .menu {
    position: absolute;
    top: 410px;
    left: 113px;
    text-align: center;
  }
  .containerfooter .footer .menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;

    display: grid;
    text-align-last: center;
  }
  .containerfooter .footer .menu li {
    font-family: RFD-Regular;
    font-weight: bold;
    font-size: 14px;
    line-height: 0px;
  }
  .containerfooter .footer .menu li a {
    display: block;
    color: #271811;
    text-align: left;
    padding: 15px 0;
    text-decoration: none;
  }
  .containerfooter .footer .footersocial {
    position: absolute;
    top: 640px;
    left: 85px;
    text-align: center;
  }
  .containerfooter .footer .footersocial .social img {
    width: 30px;
    padding-right: 32px;
    vertical-align: middle;
  }
  .containerfooter .footer .footersocial .pc {
    padding: 18px 0;
  }
  .containerfooter .footer .footersocial .pc a {
    font-family: RFD-Light;
    font-size: 12px;
  }
  .containerfooter .footer .footersocial .pc a {
    color: #271811;
  }
  .containerfooter .footer .footersocial .pc a:active {
    color: #271811;
  }
  .containerfooter .footer .footersocial .pc a:hover {
    color: #271811;
  }
  .containerfooter .footer .footersocial .pc a:visited {
    color: #271811;
  }
  .containerfooter .footer .footersocial .copy {
    font-family: RFD-Light;
    font-size: 12px;
  }
}
</style>

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    meta: { layout: "main" },
    component: () => import("../layouts/MainLayout.vue"),
  },
  {
    path: "/history",
    name: "history",
    meta: { layout: "second" },
    component: () => import("../views/History.vue"),
  },
  {
    path: "/activity",
    name: "activity",
    meta: { layout: "third" },
    component: () => import("../layouts/ThirdLayout.vue"),
  },
  {
    path: "/vacancies",
    name: "vacancies",
    meta: { layout: "vacancies" },
    component: () => import("../layouts/VacanciesLayout.vue"),
  },
  {
    path: "/partners",
    name: "partners",
    meta: { layout: "partners" },
    component: () => import("../layouts/PartnersLayout.vue"),
  },
  {
    path: "/students",
    name: "students",
    meta: { layout: "students" },
    component: () => import("../layouts/StudentsLayout.vue"),
  },
  {
    path: "/contacts",
    name: "contacts",
    meta: { layout: "contacts" },
    component: () => import("../layouts/ContactsLayout.vue"),
  },
  {
    path: "/news",
    name: "news",
    meta: { layout: "news" },
    component: () => import("../views/News.vue"),
  },
  {
    path: "/news/:id",
    name: "deatail_news",
    meta: { layout: "news" },
    component: () => import("../views/DetailNews.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

export default router;

<template>
  <div class="menu" :class="{ openmenu: isOpen }">
    <img class="cruze" src="../assets/cruze.png" alt="" />
    <img class="logomenu" src="../assets/logomenu.png" alt="" />
    <ul>
      <li>
        <router-link to="/history" exact active-class="active"
          >ИСТОРИЯ</router-link
        >
      </li>
      <li>
        <router-link to="/activity" active-class="active"
          >ДЕЯТЕЛЬНОСТЬ</router-link
        >
      </li>
      <li>
        <router-link to="/vacancies" active-class="active"
          >ВАКАНСИИ</router-link
        >
      </li>
      <li>
        <router-link to="/students" active-class="active"
          >СТУДЕНТАМ</router-link
        >
      </li>
      <li>
        <router-link to="/partners" active-class="active"
          >ПАРТНЕРАМ</router-link
        >
      </li>
      <li>
        <router-link to="/contacts" active-class="active">КОНТАКТЫ</router-link>
      </li>
    </ul>
    <!-- <div class="clickGeneral" @click="$router.push('/')"></div> -->
  </div>
  <div class="social">
    <a href="https://vk.com/zaryaputino" target="_blank" rel="vk"
      ><img class="left" src="../assets/vkblack.png" alt="" width="30"
    /></a>
  </div>
</template>
<script>
export default {
  props: ["isOpen"],
  data() {
    return {};
  },
  mounted() {
    // console.log("isOpen -- ", isOpen);
  },
};
</script>
<style scoped>
.openmenu {
  visibility: visible;
}
.cruze {
  position: absolute;
  top: 17px;
  right: 17px;
  width: 16.67px;
  height: 16.71;
}
.logomenu {
  position: absolute;
  top: 40px;
  width: 272px;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.menu {
  display: block;
  visibility: hidden;
  position: absolute;
  width: 375px;
  height: 583px;
  /* left: 0px; */
  top: 0px;
  background-color: white;
}
.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  top: 142px;
}
.menu li {
  /* float: left; */
  font-family: RFD-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 17px; */
}
.menu li a {
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.menu li a:hover {
  color: #ff4b00;
}
.clickGeneral {
  position: absolute;
  top: -115px;
  left: 5px;
  width: 200px;
  height: 40px;
}
.menu .router-link-exact-active {
  color: #ff4b00;
}
.container .maingbg .social {
  position: absolute;
  left: 155px;
  top: 450px;
  width: 90px;
  z-index: 1;
  height: 32px;
}
.container .maingbg .social .left {
  float: left;
  position: absolute;
  bottom: 2px;
}
.container .maingbg .social .right {
  float: right;
  position: absolute;
  bottom: 0px;
  left: 62px;
}
</style>

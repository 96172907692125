<template>
  <div class="container">
    <div class="maingbg">
      <a href="/"> <div class="logo"></div></a>
      <div class="hamburger">
        <a href="#" @click.prevent="isOpenM">
          <img class="hamburger" src="../assets/hamburgermenu.png" alt=""
        /></a>
      </div>
      <div class="menu" v-if="isOpen">
        <a href="#" @click.prevent="isOpenM">
          <img class="cruze" src="../assets/cruze.png" alt=""
        /></a>
        <img class="logomenu" src="../assets/logomenu.png" alt="" />
        <ul>
          <li>
            <router-link to="/history" exact active-class="active"
              >ИСТОРИЯ</router-link
            >
          </li>
          <li>
            <router-link to="/activity" active-class="active"
              >ДЕЯТЕЛЬНОСТЬ</router-link
            >
          </li>
          <li>
            <router-link to="/vacancies" active-class="active"
              >ВАКАНСИИ</router-link
            >
          </li>
          <li>
            <router-link to="/students" active-class="active"
              >СТУДЕНТАМ</router-link
            >
          </li>
          <li>
            <router-link to="/partners" active-class="active"
              >ПАРТНЕРАМ</router-link
            >
          </li>
          <li>
            <router-link to="/contacts" active-class="active"
              >КОНТАКТЫ</router-link
            >
          </li>
          <li>
            <router-link to="/news" active-class="active">НОВОСТИ</router-link>
          </li>
        </ul>

        <div class="socialblack">
          <a href="https://vk.com/zaryaputino" target="_blank" rel="vk"
            ><img class="left" src="../assets/vkblack.png" alt="" width="30"
          /></a>
        </div>
      </div>
      <div class="title">
        <div class="maintitle">НОВОСТИ</div>
      </div>
    </div>
  </div>
  <div class="containergeneral">
    <router-view></router-view>
  </div>
  <footer-site></footer-site>
</template>
<script>
import FooterSite from "../components/Footer.vue";
import MenuTop from "../components/MenuTop.vue";
export default {
  data() {
    return {
      isOpen: false,
      fon: 'url("../assets/history/bghistory.png");',
    };
  },
  methods: {
    isOpenM() {
      if (this.isOpen == true) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
    },
  },
  components: {
    FooterSite,
    MenuTop,
  },
};
</script>
<style scoped>
.container {
  position: relative;
  margin: 0 auto;
  /* width: 375px; */
  height: 100%;
}
.containergeneral {
  position: relative;
  width: 375px;
  margin: 0 auto;
}
.container .maingbg {
  margin: 0 auto;
  background: url("../assets/history/bghistory.png");
  max-width: 414px;
  width: 100%;
  height: 385px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.container .maingbg .logo {
  background-image: url("../assets/logo.png");
  position: relative;
  width: 197px;
  height: 37px;
  left: 90px;
  top: 47px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.openmenu {
  visibility: visible;
  display: block;
  position: absolute;
  width: 375px;
  height: 583px;
  top: 0px;
  background-color: white;
}
.cruze {
  position: absolute;
  top: 17px;
  right: 17px;
  width: 16.67px;
  z-index: 100;
}
.logomenu {
  position: absolute;
  top: 40px;
  width: 272px;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.menu {
  display: block;
  position: absolute;
  max-width: 414px;
  z-index: 100;
  width: 100%;
  height: 583px;
  top: 0px;
  background-color: white;
}
.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  top: 142px;
}
.menu li {
  /* float: left; */
  font-family: RFD-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 17px; */
}
.menu li a {
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.menu li a:hover {
  color: #ff4b00;
}
.clickGeneral {
  position: absolute;
  top: -115px;
  left: 5px;
  width: 200px;
  height: 40px;
}
.menu .router-link-exact-active {
  color: #ff4b00;
}
.container .maingbg .socialblack {
  position: absolute;
  left: 155px;
  top: 450px;
  width: 90px;
  z-index: 101;
  height: 32px;
}
.container .maingbg .socialblack .left {
  float: left;
  position: absolute;
  bottom: 2px;
}
.container .maingbg .socialblack .right {
  float: right;
  position: absolute;
  bottom: 0px;
  left: 62px;
}
.container .maingbg .title {
  display: block;
  position: relative;
  left: 30px;
  top: 240px;
  width: 332px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 30px;
  line-height: 17px;
  z-index: 1;
}
.container .maingbg .title .maintitle {
  font-size: 30px;
  line-height: 84px;
  color: #ffffff;
}
.container .maingbg .title .secondtitle {
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
}
</style>

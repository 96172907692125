<template>
  <div class="container">
    <div class="maingbg">
      <a href="/"> <div class="logo"></div></a>
      <div class="hamburger">
        <a href="#" @click.prevent="isOpenM">
          <img class="hamburger" src="../assets/hamburgermenu.png" alt=""
        /></a>
      </div>
      <div class="menu" v-if="isOpen">
        <a href="#" @click.prevent="isOpenM">
          <img class="cruze" src="../assets/cruze.png" alt=""
        /></a>
        <img class="logomenu" src="../assets/logomenu.png" alt="" />
        <ul>
          <li>
            <router-link to="/history" exact active-class="active"
              >ИСТОРИЯ</router-link
            >
          </li>
          <li>
            <router-link to="/activity" active-class="active"
              >ДЕЯТЕЛЬНОСТЬ</router-link
            >
          </li>
          <li>
            <router-link to="/vacancies" active-class="active"
              >ВАКАНСИИ</router-link
            >
          </li>
          <li>
            <router-link to="/students" active-class="active"
              >СТУДЕНТАМ</router-link
            >
          </li>
          <li>
            <router-link to="/partners" active-class="active"
              >ПАРТНЕРАМ</router-link
            >
          </li>
          <li>
            <router-link to="/contacts" active-class="active"
              >КОНТАКТЫ</router-link
            >
          </li>
          <li>
            <router-link to="/news" active-class="active">НОВОСТИ</router-link>
          </li>
        </ul>

        <div class="socialblack">
          <a href="https://vk.com/zaryaputino" target="_blank" rel="vk"
            ><img class="left" src="../assets/vkblack.png" alt="" width="30"
          /></a>
        </div>
      </div>
      <div class="title">
        <div class="maintitle">СТУДЕНТАМ</div>
      </div>
    </div>
  </div>
  <div class="containerPractice">
    <div class="title">
      Приглашаем на практику студентов следующих направлений*:
    </div>
    <div class="spisok">
      <ul>
        <li v-for="sp in containerPracticeSpisok" :key="sp">
          {{ sp }}
        </li>
        <!-- <li>Агроинженерия</li>
        <li>Наземнотранспортно - технологические средства</li>
        <li>Техносферная безопасность</li>
        <li>Эксплуатация транспортно - технологических машин и комплексов</li>
        <li>Почвоведение</li>
        <li>Агрохимия и Агропочвоведение</li>
        <li>Ветеринария</li>
        <li>Зоотехния</li> -->
      </ul>
    </div>
    <div class="smalltext">
      * если вы не нашли свое направление в списке, свяжитесь с нами
    </div>
    <img src="../assets/students/lines.png" alt="" class="lines" />
    <img
      src="../assets/students/redrombsmall.png"
      alt=""
      class="redrombsmall"
    />
    <img src="../assets/students/blackromb.png" alt="" class="blackromb" />
    <!-- <img src="../assets/students/redrombbig.png" alt="" class="redrombbig" /> -->
  </div>
  <div class="containerHowPractice">
    <div class="title">
      Как пройти практику на агропредприятии «Заря Путино»?
    </div>
    <div class="card1">
      <img src="../assets/students/uno.png" alt="" class="uno" />
      <img src="../assets/students/card.png" alt="" class="card" />
      <div class="text" v-html="containerHowPracticeList[0]"></div>
    </div>
    <div class="card2">
      <img src="../assets/students/dos.png" alt="" class="uno" />
      <img src="../assets/students/card.png" alt="" class="card" />
      <div class="text" v-html="containerHowPracticeList[1]"></div>
    </div>
    <div class="card3">
      <img src="../assets/students/tres.png" alt="" class="uno" />
      <img src="../assets/students/card.png" alt="" class="card" />
      <div class="text" v-html="containerHowPracticeList[2]"></div>
    </div>
    <div class="smalltext">
      *Если студент не имеет портфолио, то достаточно будет отправить только
      мотивационное письмо.
    </div>
  </div>
  <div class="containerpoliticaRucovodstva">
    <img src="../assets/students/blackromb2.png" alt="" class="blackromb2" />
    <img src="../assets/students/redline.png" alt="" class="redline" />
    <div class="title" v-html="containerpoliticaRucovodstvaList[0]"></div>
    <div class="subtitle" v-html="containerpoliticaRucovodstvaList[1]"></div>
    <div class="contact" v-html="containerpoliticaRucovodstvaList[2]"></div>
  </div>
  <footer-site></footer-site>
</template>
<script>
import MenuTop from "../components/MenuTop.vue";
import FooterSite from "../components/Footer.vue";
export default {
  data() {
    return {
      hola: false,
      isOpen: false,
      containerPracticeSpisok: [],
      containerHowPracticeList: [],
      containerpoliticaRucovodstvaList: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetch("https://cb96744.tmweb.ru/api/articles/");
      const finalRes = await res.json();
      this.containerPracticeSpisok = finalRes[34].text.split(";");
      this.containerHowPracticeList = finalRes[35].text.split(";");
      this.containerpoliticaRucovodstvaList = finalRes[36].text.split(";");
    },
    isOpenM() {
      if (this.isOpen == true) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
    },
  },
  components: {
    MenuTop,
    FooterSite,
  },
};
</script>
<style scoped>
.container {
  position: relative;
  height: 100%;
}
.container .maingbg {
  margin: 0 auto;
  background: url("../assets/students/bgstudents.png");
  max-width: 414px;
  width: 100%;
  height: 386px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.container .maingbg .logo {
  background-image: url("../assets/logo.png");
  position: relative;
  width: 197px;
  height: 37px;
  left: 90px;
  top: 47px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.openmenu {
  visibility: visible;
  display: block;
  position: absolute;
  width: 375px;
  height: 583px;
  top: 0px;
  background-color: white;
}
.cruze {
  position: absolute;
  top: 17px;
  right: 17px;
  width: 16.67px;
  z-index: 100;
}
.logomenu {
  position: absolute;
  top: 40px;
  width: 272px;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.menu {
  display: block;
  position: absolute;
  max-width: 414px;
  z-index: 100;
  width: 100%;
  height: 583px;
  top: 0px;
  background-color: white;
}
.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  top: 142px;
}
.menu li {
  /* float: left; */
  font-family: RFD-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 17px; */
}
.menu li a {
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.menu li a:hover {
  color: #ff4b00;
}
.clickGeneral {
  position: absolute;
  top: -115px;
  left: 5px;
  width: 200px;
  height: 40px;
}
.menu .router-link-exact-active {
  color: #ff4b00;
}
.container .maingbg .socialblack {
  position: absolute;
  left: 155px;
  top: 450px;
  width: 90px;
  z-index: 101;
  height: 32px;
}
.container .maingbg .socialblack .left {
  float: left;
  position: absolute;
  bottom: 2px;
}
.container .maingbg .socialblack .right {
  float: right;
  position: absolute;
  bottom: 0px;
  left: 62px;
}
.container .maingbg .title {
  display: block;
  position: relative;
  left: 31px;
  top: 240px;
  width: 332px;
  font-family: RFD-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 17px;
}
.container .maingbg .title .maintitle {
  font-size: 30px;
  line-height: 84px;
  color: #ffffff;
}
.container .maingbg .title .secondtitle {
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
}
.containerPractice {
  position: relative;
  width: 375px;
  margin: 0 auto;
  height: 860px;
}
.containerPractice .title {
  font-family: RFD-Regular;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 50px;
  left: 7px;
  text-align: center;
  width: 360px;
  line-height: 130%;
}
.containerPractice .spisok {
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  top: 150px;
  left: 55px;
  width: 310px;
}
.containerPractice .spisok ul {
  margin-left: 0;
  /* Отступ слева в браузере IE и Opera */
  padding-left: 0;
  margin-top: auto;
}
.containerPractice .spisok ul li {
  list-style-type: none;
  margin: 0 0 33px 0;
}
.containerPractice .smalltext {
  font-family: RFD-Light;
  line-height: 130%;
  font-size: 12px;
  position: absolute;
  top: 600px;
  left: 15px;
  width: 289px;
}
.containerPractice .lines {
  position: absolute;
  left: 0px;
  top: 159px;
  width: 37px;
  height: 405px;
}
.containerPractice .redrombsmall {
  position: absolute;
  top: 760px;
  left: 0px;
  width: 255px;
  height: 71px;
}
.containerPractice .blackromb {
  position: absolute;
  top: 660px;
  right: 0px;
  width: 255px;
  height: 71px;
}
.containerPractice .redrombbig {
  position: absolute;
  top: 0px;
  right: 130px;
  width: 71px;
  height: 629px;
}
.containerHowPractice {
  position: relative;
  width: 375px;
  margin: 0 auto;
  height: 1000px;
}
.containerHowPractice .title {
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 18px;
  position: absolute;
  top: 0px;
  left: 46px;
  width: 282px;
  text-align: center;
}
.containerHowPractice .card1 {
  position: absolute;
  width: 345px;
  height: 186px;
  top: 100px;
  left: 15px;
}
.containerHowPractice .card1 .uno {
  position: absolute;
  width: 73px;
  height: 160px;
  top: 0px;
  left: 0px;
}
.containerHowPractice .card1 .card {
  position: absolute;
  width: 345px;
  top: 45px;
  left: 0px;
  z-index: -1;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.containerHowPractice .card1 .text {
  font-family: RFD-Light;
  line-height: 130%;
  font-size: 14px;
  position: absolute;
  top: 110px;
  left: 56px;
  width: 261px;
}
.containerHowPractice .card2 {
  position: absolute;
  width: 345px;
  height: 186px;
  top: 360px;
  left: 15px;
}
.containerHowPractice .card2 .uno {
  position: absolute;
  width: 135px;
  height: 160px;
  top: 0px;
  left: 0px;
}
.containerHowPractice .card2 .card {
  position: absolute;
  width: 345px;
  top: 45px;
  left: 0px;
  z-index: -1;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.containerHowPractice .card2 .text {
  font-family: RFD-Light;
  line-height: 130%;
  font-size: 14px;
  position: absolute;
  top: 95px;
  left: 80px;
  width: 261px;
}
.containerHowPractice .card3 {
  position: absolute;
  width: 345px;
  height: 186px;
  top: 620px;
  left: 15px;
}
.containerHowPractice .card3 .uno {
  position: absolute;
  width: 145px;
  height: 160px;
  top: 0px;
  left: 0px;
}
.containerHowPractice .card3 .card {
  position: absolute;
  width: 345px;
  top: 45px;
  left: 0px;
  z-index: -1;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.containerHowPractice .card3 .text {
  font-family: RFD-Light;
  line-height: 130%;
  font-size: 14px;
  position: absolute;
  top: 95px;
  left: 69px;
  width: 282px;
}
.containerHowPractice .smalltext {
  font-family: RFD-Light;
  line-height: 130%;
  font-size: 12px;
  width: 345px;
  position: absolute;
  top: 890px;
  left: 15px;
}
.containerpoliticaRucovodstva {
  position: relative;
  width: 375px;
  margin: 0 auto;
  height: 760px;
  /* margin-top: 57px; */
}
.containerpoliticaRucovodstva .title {
  font-family: RFD-Regular;
  font-weight: normal;
  line-height: 130%;
  font-size: 16px;
  color: white;
  width: 323px;
  position: absolute;
  top: 22px;
  /* line-height: 26px; */
  left: 30px;
}
.containerpoliticaRucovodstva .subtitle {
  font-family: RFD-Regular;
  line-height: 130%;
  font-size: 14px;
  color: white;
  width: 338px;
  position: absolute;
  top: 370px;
  left: 15px;
}
.containerpoliticaRucovodstva .contact {
  font-family: RFD-Regular;
  font-weight: bold;
  line-height: 130%;
  font-size: 16px;
  text-align: center;
  width: 345px;
  position: absolute;
  top: 610px;
  left: 15px;
  /* line-height: 26px; */
}
.containerpoliticaRucovodstva .blackromb2 {
  position: absolute;
  width: 375px;
  height: 579px;
  top: 0px;
  left: 0px;
}
.containerpoliticaRucovodstva .redline {
  position: absolute;
  transform: rotate(90deg);
  width: 315px;
  border: 2px solid #ff4b00;
  height: 0px;
  top: 175px;
  left: -145px;
}
.bF {
  font-family: RFD-Bold;
}
.phoneColor {
  color: #ff4b00;
}
</style>

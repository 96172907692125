<template>
  <div class="container">
    <div class="maingbg">
      <a href="/"> <div class="logo"></div></a>
      <!-- <video autoplay muted loop class="myVideo">
        <source src="../assets/video/zp.mp4" type="video/mp4" />
      </video> -->
      <div class="hamburger">
        <a href="#" @click.prevent="isOpenM">
          <img class="hamburger" src="../assets/hamburgermenu.png" alt=""
        /></a>
      </div>
      <div class="menu" v-if="isOpen">
        <a href="#" @click.prevent="isOpenM">
          <img class="cruze" src="../assets/cruze.png" alt=""
        /></a>
        <img class="logomenu" src="../assets/logomenu.png" alt="" />
        <ul>
          <li>
            <router-link to="/history" exact active-class="active"
              >ИСТОРИЯ</router-link
            >
          </li>
          <li>
            <router-link to="/activity" active-class="active"
              >ДЕЯТЕЛЬНОСТЬ</router-link
            >
          </li>
          <li>
            <router-link to="/vacancies" active-class="active"
              >ВАКАНСИИ</router-link
            >
          </li>
          <li>
            <router-link to="/students" active-class="active"
              >СТУДЕНТАМ</router-link
            >
          </li>
          <li>
            <router-link to="/partners" active-class="active"
              >ПАРТНЕРАМ</router-link
            >
          </li>
          <li>
            <router-link to="/contacts" active-class="active"
              >КОНТАКТЫ</router-link
            >
          </li>
          <li>
            <router-link to="/news" active-class="active">НОВОСТИ</router-link>
          </li>
        </ul>
        <div class="socialblack">
          <a href="https://vk.com/zaryaputino" target="_blank" rel="vk"
            ><img class="left" src="../assets/vkblack.png" alt="" width="30"
          /></a>
        </div>
      </div>
      <div class="title">
        <div class="maintitle">ЗАРЯ ПУТИНО</div>
        <div class="secondtitle">АГРОПРЕДПРИЯТИЕ</div>
      </div>
      <div class="social">
        <a href="https://vk.com/zaryaputino" target="_blank" rel="vk"
          ><img class="left" src="../assets/vk.png" alt="" width="30"
        /></a>
      </div>
    </div>
  </div>
  <div class="containerAboutCompany">
    <div class="aboutCompany" @click="this.$router.push('/history')">
      <div class="smeshenieRight">
        <div class="title">
          <div class="redromb">
            <img src="../assets/redromb.png" width="69" alt="" />
          </div>
          <div class="OCompany">О КОМПАНИИ</div>
        </div>
        <div class="text">
          <div class="zalivka"></div>
          <div class="opisanie" v-html="aboutCompanyOpisanie"></div>
          <div class="btn">
            <button class="podrobnee">
              <router-link to="/history">ПОДРОБНЕЕ</router-link>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="containerimagesOcompany">
    <div class="imagesOcompany">
      <div
        class="im1"
        @mouseover="hoverImg = true"
        @mouseleave="hoverImg = false"
      >
        <img :class="{ hImg: hoverImg }" src="../assets/1oc.png" alt="" />
      </div>
      <div
        class="im2"
        @mouseover="hoverImg1 = true"
        @mouseleave="hoverImg1 = false"
      >
        <img :class="{ hImg1: hoverImg1 }" src="../assets/2oc.png" alt="" />
      </div>
      <div
        class="im3"
        @mouseover="hoverImg2 = true"
        @mouseleave="hoverImg2 = false"
      >
        <img :class="{ hImg2: hoverImg2 }" src="../assets/3oc.png" alt="" />
      </div>
      <div
        class="im4"
        @mouseover="hoverImg3 = true"
        @mouseleave="hoverImg3 = false"
      >
        <img :class="{ hImg3: hoverImg3 }" src="../assets/4oc.png" alt="" />
      </div>
    </div>
  </div>
  <div class="containerOnasVcifrax">
    <div class="OnasVcifrax">
      <div
        class="moloko"
        @mouseover="molokoImg = true"
        @mouseleave="molokoImg = false"
      >
        <img :class="{ cImg: molokoImg }" src="../assets/moloko.png" alt="" />
        <div v-html="moloko"></div>
      </div>
      <div class="cow" @mouseover="cowImg = true" @mouseleave="cowImg = false">
        <img :class="{ cImg1: cowImg }" src="../assets/cow.png" alt="" />
        <div v-html="cow"></div>
      </div>
      <div
        class="predpriyatie"
        @mouseover="predpriyatieImg = true"
        @mouseleave="predpriyatieImg = false"
      >
        <img
          :class="{ cImg2: predpriyatieImg }"
          src="../assets/predpriyatie.png"
          alt=""
        />
        <div v-html="predpriyatie"></div>
      </div>
      <div
        class="tractor"
        @mouseover="tractorImg = true"
        @mouseleave="tractorImg = false"
      >
        <img
          :class="{ cImg3: tractorImg }"
          src="../assets/tractor.png"
          alt=""
        />
        <div v-html="tractor"></div>
      </div>
    </div>
  </div>
  <div class="containerDeyatelnost">
    <div class="deyatelnost">
      <div class="title">
        <img src="../assets/dt.png" alt="" />
        <div>ДЕЯТЕЛЬНОСТЬ</div>
      </div>
      <div class="general">
        <img class="fongeneral" src="../assets/dtbg.png" alt="" />
        <div class="trescard">
          <div class="pervii">
            <div class="title">Животноводство</div>
            <img src="../assets/kye/cow.png" alt="" />
            <!-- <div class="title">
              Производство <br />
              сырого молока
            </div>
            <div class="title">Растениеводство</div> -->
          </div>
          <div class="vtoroi">
            <div class="title">
              Производство <br />
              сырого молока
            </div>
            <img src="../assets/kye/bidon.png" alt="" />
            <!-- <div class="title">
              Производство <br />
              сырого молока
            </div>
            <div class="title">Растениеводство</div> -->
          </div>
          <div class="tretii">
            <div class="title">Растениеводство</div>
            <img src="../assets/kye/psheno.png" alt="" />
            <!-- <div class="title">
              Производство <br />
              сырого молока
            </div>
            <div class="title">Растениеводство</div> -->
          </div>
          <!-- <div class="vtoroi">
            <div
              class="img"
              @mouseover="ImgCow = true"
              @mouseleave="ImgCow = false"
            >
              <img src="../assets/kye/cow.png" alt="" />
            </div>
            <div
              class="img"
              @mouseover="ImgBidon = true"
              @mouseleave="ImgBidon = false"
            >
              <img
                :class="{ BidonImg: ImgBidon }"
                src="../assets/kye/bidon.png"
                alt=""
              />
            </div>
            <div
              class="img"
              @mouseover="ImgPsheno = true"
              @mouseleave="ImgPsheno = false"
            >
              <img
                :class="{ PshenoImg: ImgPsheno }"
                src="../assets/kye/psheno.png"
                alt=""
              />
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div class="redromb">
        <img src="../assets/redrombmini.png" alt="" />
      </div> -->
    </div>
  </div>
  <div class="containerNews">
    <div class="News">
      <div class="s1">
        <div class="img">
          <img src="../assets/RedRombNews.png" alt="" />
        </div>
        <div class="title">НОВОСТИ</div>
      </div>
      <!-- <div class="s2">
        <img
          :class="{ LeftArrow: arrowLeft }"
          src="../assets/arrowl.png"
          alt=""
          @mouseover="arrowLeft = true"
          @mouseleave="arrowLeft = false"
        />
        <img
          :class="{ RightArrow: arrowRight }"
          src="../assets/arrowr.png"
          alt=""
          @mouseover="arrowRight = true"
          @mouseleave="arrowRight = false"
        />
      </div> -->
    </div>
  </div>
  <div class="containerNewsStati">
    <div class="NewsStati">
      <div class="line1">
        <div class="card">
          <div class="text">
            <div class="title" v-html="news.last_modified"></div>
            <div class="subtitle" v-html="news.title"></div>
            <p @click="detail_news()">Подробнее</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="containerNewsFooter" @click="this.$router.push('/news')">
    <div class="NewsFooter">
      <div class="btn">
        <button class="vseNews" type="button">ВСЕ НОВОСТИ</button>
      </div>
    </div>
  </div>
  <div class="containerKomanda">
    <div class="komanda">
      <div class="title">КОМАНДА РУКОВОДИТЕЛЕЙ</div>
    </div>
  </div>
  <div class="containerKomandaKvadrat">
    <div class="komandakvadrat">
      <div class="img1">
        <img src="../assets/redkvadrat.png" alt="" />
      </div>
    </div>
  </div>

  <div class="containerKomandaStati">
    <img
      src="../assets/swipe.png"
      width="50"
      height="50"
      style="margin: 15px"
    />
    <div class="KomandaStati">
      <div class="slider">
        <slider v-bind="options">
          <div class="item">
            <div
              class="card"
              @mouseover="komanda1 = true"
              @mouseleave="komanda1 = false"
              :class="{ kom1: komanda1 }"
            >
              <div class="img">
                <img src="../assets/ArmenK.png" alt="" />
              </div>
              <div v-html="person1"></div>
            </div>
          </div>
          <div class="item">
            <div
              class="card"
              @mouseover="komanda11 = true"
              @mouseleave="komanda11 = false"
              :class="{ kom11: komanda11 }"
            >
              <div class="img">
                <img src="../assets/BobrovaM.png" alt="" />
              </div>
              <div v-html="person2"></div>
            </div>
          </div>
          <div class="item">
            <div
              class="card"
              @mouseover="komanda111 = true"
              @mouseleave="komanda111 = false"
              :class="{ kom111: komanda111 }"
            >
              <div class="img">
                <img src="../assets/NoskovaA.png" alt="" />
              </div>
              <div v-html="person3"></div>
            </div>
          </div>
          <div class="item">
            <div
              class="card"
              @mouseover="komanda2 = true"
              @mouseleave="komanda2 = false"
              :class="{ kom2: komanda2 }"
            >
              <div class="img">
                <img src="../assets/VolegovaV.png" alt="" />
              </div>
              <div v-html="person4"></div>
            </div>
          </div>
          <div class="item">
            <div
              class="card"
              @mouseover="komanda22 = true"
              @mouseleave="komanda22 = false"
              :class="{ kom22: komanda22 }"
            >
              <div class="img">
                <img src="../assets/ObuxovaN.png" alt="" />
              </div>
              <div v-html="person5"></div>
            </div>
          </div>
          <div class="item">
            <div
              class="card"
              @mouseover="komanda222 = true"
              @mouseleave="komanda222 = false"
              :class="{ kom222: komanda222 }"
            >
              <div class="img">
                <img src="../assets/ObuxovS.png" alt="" />
              </div>
              <div v-html="person6"></div>
            </div>
          </div>
        </slider>
      </div>
    </div>
  </div>
  <!-- <div class="containerKomandaKvadratNiz">
    <div class="komandakvadratniz">
      <div class="img1">
        <img src="../assets/redkvadrat.png" alt="" />
      </div>
      <div class="img2">
        <img src="../assets/blackpolosa.png" alt="" />
      </div>
    </div>
  </div> -->
  <footer-site></footer-site>
</template>

<script>
import FooterSite from "../components/Footer.vue";
import MenuTop from "../components/MenuTop.vue";
import Slider from "../components/Slider.vue";

export default {
  name: "mainlayout",
  data() {
    return {
      isOpen: false,
      hoverImg: false,
      hoverImg1: false,
      hoverImg2: false,
      hoverImg3: false,
      molokoImg: false,
      cowImg: false,
      predpriyatieImg: false,
      tractorImg: false,
      ImgPsheno: false,
      ImgCow: false,
      ImgBidon: false,
      arrowRight: false,
      arrowLeft: false,
      komanda1: false,
      komanda11: false,
      komanda111: false,
      komanda2: false,
      komanda22: false,
      komanda222: false,
      aboutCompanyOpisanie: "",
      moloko: "",
      cow: "",
      predpriyatie: "",
      tractor: "",
      person1: "",
      person2: "",
      person3: "",
      person4: "",
      person5: "",
      person6: "",
      news: [],
      options: {
        items: 1,
        margin: 20,
        nav: false,
        dots: true,
        loop: true,
        timing: "cubic-bezier(0, 0.72, 0.64, 1.06)",
        offset: 1,
        sibling: true,
        responsive: {},
      },
    };
  },
  async mounted() {
    this.getData();
    const response = await fetch("https://cb96744.tmweb.ru/api/news/");
    const news_arr = await response.json();
    this.news = news_arr.at(-1);
  },
  methods: {
    async getData() {
      const res = await fetch("https://cb96744.tmweb.ru/api/articles/");
      const finalRes = await res.json();
      this.aboutCompanyOpisanie = finalRes[0].text;
      this.moloko = finalRes[1].text;
      this.cow = finalRes[2].text;
      this.predpriyatie = finalRes[3].text;
      this.tractor = finalRes[4].text;
      this.person1 = finalRes[5].text;
      this.person2 = finalRes[6].text;
      this.person3 = finalRes[7].text;
      this.person4 = finalRes[8].text;
      this.person5 = finalRes[9].text;
      this.person6 = finalRes[10].text;
    },
    isOpenM() {
      if (this.isOpen == true) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
    },
    detail_news() {
      this.$router.push({ name: "deatail_news", params: { id: this.news.id } });
    },
  },
  components: {
    FooterSite,
    MenuTop,
    Slider,
  },
};
</script>
<style scoped>
body {
  margin: 0 auto;
}

@media only screen and (max-width: 479px) {
  .container {
    position: relative;
    height: 100%;
  }
  .container .maingbg {
    margin: 0 auto;
    background-image: url("../assets/MainBG.png");
    max-width: 414px;
    width: 100%;
    height: 812px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
  .container .myVideo {
    margin: 0 auto;
    width: 375px;
    height: 931px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -3;
  }
  .container .maingbg .logo {
    background-image: url("../assets/logo.png");
    position: relative;
    width: 197px;
    height: 37px;
    left: 90px;
    top: 47px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .container .maingbg .title {
    display: block;
    position: relative;
    left: 20px;
    top: 580px;
    width: 350px;
    font-family: RFD-Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  }
  .container .maingbg .title .maintitle {
    font-size: 35px;
    font-weight: bold;
    line-height: 45px;
    color: #ffffff;
  }
  .container .maingbg .title .secondtitle {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
  }
  .container .maingbg .social {
    position: relative;
    left: 50%;
    transform: translateX(-45%);
    top: 640px;
    width: 115px;
  }
  .container .maingbg .social a {
    margin-right: 30px;
  }
  .openmenu {
    visibility: visible;
    display: block;
    position: absolute;
    width: 375px;
    height: 583px;
    top: 0px;
    background-color: white;
  }
  .cruze {
    position: absolute;
    top: 17px;
    right: 17px;
    width: 16.67px;
    z-index: 100;
  }
  .logomenu {
    position: absolute;
    top: 40px;
    width: 272px;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .menu {
    display: block;
    position: absolute;
    max-width: 414px;
    z-index: 100;
    width: 100%;
    height: 583px;
    top: 0px;
    background-color: white;
  }
  .menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    top: 142px;
  }
  .menu li {
    /* float: left; */
    font-family: RFD-Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    /* line-height: 17px; */
  }
  .menu li a {
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  .menu li a:hover {
    color: #ff4b00;
  }
  .clickGeneral {
    position: absolute;
    top: -115px;
    left: 5px;
    width: 200px;
    height: 40px;
  }
  .menu .router-link-exact-active {
    color: #ff4b00;
  }
  .container .maingbg .socialblack {
    position: absolute;
    left: 155px;
    top: 460px;
    width: 90px;
    z-index: 1;
    height: 32px;
  }
  .container .maingbg .socialblack .left {
    float: left;
    position: absolute;
    bottom: 2px;
  }
  .container .maingbg .socialblack .right {
    float: right;
    position: absolute;
    bottom: 0px;
    left: 62px;
  }
  .containerAboutCompany {
    position: relative;
    height: 100%;
  }
  .containerAboutCompany .aboutCompany {
    margin: 0 auto;
    width: 375px;
    height: 560px;
  }
  .containerAboutCompany .aboutCompany .smeshenieRight .title .redromb img {
    z-index: -1;
    position: relative;
    top: 0px;
  }
  .containerAboutCompany .aboutCompany .smeshenieRight .title .OCompany {
    font-family: RFD-Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 48px;
    color: #271811;
    position: relative;
    width: 300px;
    height: 48px;
    left: 20px;
    top: -110px;
  }
  .containerAboutCompany .aboutCompany .smeshenieRight .title {
    width: 310px;
    float: left;
  }
  .containerAboutCompany .aboutCompany .smeshenieRight {
    margin-left: 49px;
  }
  .containerAboutCompany .aboutCompany .smeshenieRight .text {
    position: relative;
    width: 375px;
    height: 399px;
    float: right;
    top: -80px;
    z-index: -2;
    margin-bottom: 25px;
  }
  .containerAboutCompany .aboutCompany .smeshenieRight .text .zalivka {
    background-color: #46403e;
    float: right;
    width: 375px;
    height: 398px;
  }
  .containerAboutCompany .aboutCompany .smeshenieRight .text .ramka {
    position: absolute;
    top: 94px;
    width: 375px;
  }
  .containerAboutCompany .aboutCompany .smeshenieRight .text .ramka img {
    width: 100%;
  }
  .containerAboutCompany .aboutCompany .smeshenieRight .text .opisanie {
    position: absolute;
    color: #ffffff;
    width: 342px;
    top: 40px;
    left: 10px;
    font-family: RFD-Light;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
  }
  .containerAboutCompany .aboutCompany .smeshenieRight .text .opisanie p span {
    font-family: RFD-Bold;
  }
  .containerAboutCompany .aboutCompany .smeshenieRight .text .btn .podrobnee {
    font-family: RFD-Regular;
    font-size: 16px;
    position: absolute;
    width: 270px;
    height: 60px;
    color: #ffffff;
    background-color: #ff4b00;
    box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    top: 365px;
    left: 49px;
    border: 0px;
  }
  .containerAboutCompany .aboutCompany .smeshenieRight .text .btn .podrobnee a {
    text-decoration: none;
    color: white;
  }
  .containerimagesOcompany {
    position: relative;
    height: 100%;
  }
  .containerimagesOcompany .imagesOcompany {
    position: relative;
    margin: 0 auto;
    width: 375px;
    height: 750px;
  }
  .containerimagesOcompany .imagesOcompany .im1 img {
    width: 317px;
    border-radius: 5px;
  }
  .containerimagesOcompany .imagesOcompany .im1 {
    position: absolute;
    top: 385px;
    left: 15px;
  }
  .containerimagesOcompany .imagesOcompany .im2 img {
    width: 254px;
    border-radius: 5px;
  }
  .containerimagesOcompany .imagesOcompany .im2 {
    position: absolute;
    top: 560px;
    right: 15px;
  }
  .containerimagesOcompany .imagesOcompany .im3 img {
    width: 254px;
    border-radius: 5px;
  }
  .containerimagesOcompany .imagesOcompany .im3 {
    position: absolute;
    top: 245px;
    right: 15px;
    z-index: 1;
  }
  .containerimagesOcompany .imagesOcompany .im4 img {
    width: 315px;
    border-radius: 5px;
  }
  .containerimagesOcompany .imagesOcompany .im4 {
    position: absolute;
    left: 15px;
    top: 50px;
  }
  .containerOnasVcifrax {
    position: relative;
  }
  .containerOnasVcifrax .OnasVcifrax {
    position: relative;
    margin: 0 auto;
    width: 375px;
    height: 920px;
  }
  .containerOnasVcifrax .OnasVcifrax .moloko img {
    width: 110px;
  }
  .containerOnasVcifrax .OnasVcifrax .moloko {
    text-align: center;
  }
  .containerOnasVcifrax .OnasVcifrax .moloko div {
    font-family: RFD-Bold;
    font-size: 40px;
  }
  .containerOnasVcifrax .OnasVcifrax .moloko p {
    font-family: RFD-Regular;
    font-weight: bold;
    font-size: 14px;
  }
  .containerOnasVcifrax .OnasVcifrax .cow img {
    width: 110px;
  }
  .containerOnasVcifrax .OnasVcifrax .cow {
    text-align: center;
  }
  .containerOnasVcifrax .OnasVcifrax .cow div {
    font-family: RFD-Bold;
    font-size: 40px;
  }
  .containerOnasVcifrax .OnasVcifrax .cow p {
    font-family: RFD-Regular;
    font-size: 14px;
    font-weight: bold;
  }
  .containerOnasVcifrax .OnasVcifrax .predpriyatie img {
    width: 110px;
  }
  .containerOnasVcifrax .OnasVcifrax .predpriyatie {
    text-align: center;
  }
  .containerOnasVcifrax .OnasVcifrax .predpriyatie div {
    font-family: RFD-Bold;
    font-size: 40px;
  }
  .containerOnasVcifrax .OnasVcifrax .predpriyatie p {
    font-family: RFD-Regular;
    font-weight: bold;
    font-size: 14px;
  }
  .containerOnasVcifrax .OnasVcifrax .tractor img {
    width: 110px;
  }
  .containerOnasVcifrax .OnasVcifrax .tractor {
    text-align: center;
  }
  .containerOnasVcifrax .OnasVcifrax .tractor div {
    font-family: RFD-Bold;
    font-size: 40px;
  }
  .containerOnasVcifrax .OnasVcifrax .tractor p {
    font-family: RFD-Regular;
    font-weight: bold;
    font-size: 14px;
  }
  .containerDeyatelnost {
    position: relative;
  }
  .containerDeyatelnost .deyatelnost {
    position: relative;
    margin: 0 auto;
    width: 375px;
    height: 1200px;
  }
  .containerDeyatelnost .deyatelnost .title img {
    width: 375px;
    height: 145px;
  }
  .containerDeyatelnost .deyatelnost .title div {
    position: absolute;
    top: 39px;
    left: 48px;
    color: #ffffff;
    font-family: RFD-Regular;
    font-weight: bold;
    font-size: 26px;
  }
  .containerDeyatelnost .deyatelnost .general {
    position: absolute;
    top: -95px;
  }
  .containerDeyatelnost .deyatelnost .general .fongeneral {
    width: 375px;
  }
  .containerDeyatelnost .deyatelnost .general img {
    width: 300px;
  }
  .containerDeyatelnost .deyatelnost .general .trescard {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 250px;
  }
  .containerDeyatelnost .deyatelnost .general .trescard .pervii {
    width: 100%;
  }
  .containerDeyatelnost .deyatelnost .general .trescard .pervii .title {
    font-family: RFD-Regular;
    font-weight: bold;
    font-size: 20px;
  }
  .containerDeyatelnost .deyatelnost .general .trescard .vtoroi {
    width: 100%;
    margin-top: 30px;
  }
  .containerDeyatelnost .deyatelnost .general .trescard .vtoroi .title {
    font-family: RFD-Regular;
    font-weight: bold;
    font-size: 20px;
  }
  .containerDeyatelnost .deyatelnost .general .trescard .tretii {
    width: 100%;
    margin-top: 30px;
  }
  .containerDeyatelnost .deyatelnost .general .trescard .tretii .title {
    font-family: RFD-Regular;
    font-weight: bold;
    font-size: 20px;
  }
  .containerDeyatelnost .deyatelnost .redromb {
    position: absolute;
    top: 780px;
    right: 0px;
  }
  .containerDeyatelnost .deyatelnost .redromb img {
    height: 156px;
  }
  .containerNews {
    position: relative;
  }
  .containerNews .News {
    position: relative;
    margin: 0 auto;
    width: 375px;
    height: 130px;
  }
  .containerNews .News .s1 .img img {
    width: 345px;
    height: 126px;
    margin-left: 15px;
  }
  .containerNews .News .s1 .title {
    font-family: RFD-Regular;
    font-weight: bold;
    font-size: 26px;
    position: absolute;
    top: 33px;
    left: 100px;
  }
  .containerNews .News .s2 {
    position: absolute;
    top: 400px;
    right: 140px;
  }

  .containerNews .News .s2 img {
    width: 40px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    margin-left: 15px;
  }
  .containerNewsStati {
    position: relative;
    margin-top: -40px;
  }
  .containerNewsStati .NewsStati {
    position: relative;
    margin: 0 auto;
    width: 302px;
    height: 300px;
  }
  .containerNewsStati .NewsStati .line1 {
    width: 100%;
  }
  .containerNewsStati .NewsStati .line1 .card {
    width: 302px;
    height: 280px;
    float: left;
    margin: 0;
    text-align: left;
    background: #ffffff;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  .containerNewsStati .NewsStati .line1 .card img {
    width: 373px;
    height: 280px;
    border-radius: 5px;
  }
  .containerNewsStati .NewsStati .line1 .card .text {
    padding: 0 35px;
    height: 100%;
    display: grid;
    align-items: center;
  }
  .containerNewsStati .NewsStati .line1 .card .text .title {
    font-family: RFD-Regular;
    font-size: 20px;
    color: #ff4b00;
    font-weight: bold;
    text-align: center;
  }
  .containerNewsStati .NewsStati .line1 .card .text .subtitle {
    margin-top: 20px;
    font-family: RFD-Regular;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
  }
  .containerNewsStati .NewsStati .line1 .card .text p {
    margin-top: 20px;
    font-family: RFD-Regular;
    color: #ff4b00;
    font-size: 14px;
    text-align: center;
  }
  .containerNewsStati .NewsStati .line2 {
    width: 100%;
  }
  .containerNewsStati .NewsStati .line2 .card {
    width: 373px;
    height: 280px;
    float: left;
    margin: 0 10px;
    text-align: left;
    background: #ffffff;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 30px;
  }
  .containerNewsStati .NewsStati .line2 .card img {
    width: 373px;
    height: 280px;
    border-radius: 5px;
  }
  .containerNewsStati .NewsStati .line2 .card .text {
    padding: 35px;
  }
  .containerNewsStati .NewsStati .line2 .card .text .title {
    font-family: RFD-Regular;
    font-size: 20px;
    color: #ff4b00;
  }
  .containerNewsStati .NewsStati .line2 .card .text .subtitle {
    margin-top: 20px;
    font-family: RFD-Bold;
    font-size: 14px;
  }
  .containerNewsStati .NewsStati .line2 .card .text p {
    margin-top: 20px;
    font-family: RFD-Regular;
    font-size: 14px;
  }
  .containerNewsFooter {
    position: relative;
    /* z-index: -1; */
  }
  .containerNewsFooter .NewsFooter {
    position: relative;
    margin: 0 auto;
    width: 375px;
    height: 120px;
    /* background-color: #46403e; */
  }
  .containerNewsFooter .NewsFooter .img {
    position: absolute;
    top: 22px;
    right: 90px;
  }
  .containerNewsFooter .NewsFooter .img img {
    width: 273px;
  }

  .containerNewsFooter .NewsFooter .btn {
    position: absolute;
  }
  .containerNewsFooter .NewsFooter .btn .vseNews {
    font-family: RFD-Regular;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    width: 302px;
    height: 60px;
    color: #ffffff;
    background-color: #ff4b00;
    /* background-color: #ffb597; */
    box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    top: 20px;
    left: 36px;
    border: 0px;
  }
  .containerKomanda {
    position: relative;
  }
  .containerKomanda .komanda {
    position: relative;
    margin: 0 auto;
    width: 375px;
    height: 106px;
    background-color: #46403e;
    display: grid;
    align-items: center;
  }
  .containerKomanda .komanda .title {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
    font-family: RFD-Regular;
    color: white;
  }
  .containerKomandaKvadrat {
    position: relative;
    margin-top: 72px;
  }
  .containerKomandaKvadrat .komandakvadrat {
    position: relative;
    margin: 0 auto;
    width: 375px;
    height: 550px;
  }
  .containerKomandaKvadrat .komandakvadrat .img1 {
    position: absolute;
    top: 265px;
    left: 0px;
    z-index: -1;
  }
  .containerKomandaKvadrat .komandakvadrat .img1 img {
    width: 300px;
  }
  .containerKomandaKvadrat .komandakvadrat .img2 {
    position: absolute;
    top: 129px;
    left: 50px;
  }
  .containerKomandaKvadrat .komandakvadrat .img2 img {
    width: 50px;
  }
  .containerKomandaStati {
    position: relative;
    margin-top: -620px;
    /* margin-top: 0px; */
    display: grid;
    justify-items: center;
  }
  .containerKomandaStati .KomandaStati {
    position: relative;
    margin: 0 auto;
    width: 375px;
    height: 590px;
  }
  .containerKomandaStati .KomandaStati .line1 {
    width: 100%;
    display: flex;
  }
  .containerKomandaStati .KomandaStati .line1 .card {
    width: 303px;
    height: 494px;
    float: left;
    margin: 0 10px;
    text-align: left;
    background: #ffffff;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  .containerKomandaStati .KomandaStati .slider .card {
    width: 303px;
    height: 514px;
    /* float: left; */
    margin: 0 30px;
    text-align: left;
    background: #ffffff;
    /* box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25); */
    border-radius: 5px;
  }
  .containerKomandaStati .KomandaStati .line1 .card .img {
    width: 303px;
    height: 404px;
  }
  .containerKomandaStati .KomandaStati .slider .card .img {
    width: 303px;
    height: 404px;
  }
  .containerKomandaStati .KomandaStati .line1 .card .img img {
    width: 303px;
    height: 404px;
  }
  .containerKomandaStati .KomandaStati .slider .card .img img {
    width: 303px;
    height: 404px;
  }
  .containerKomandaStati .KomandaStati .line1 .card .title {
    padding: 15px 15px 10px 15px;
    font-family: RFD-Regular;
    font-weight: bold;
    font-size: 20px;
  }
  .containerKomandaStati .KomandaStati .slider .card .title {
    padding: 15px 15px 10px 15px;
    font-family: RFD-Regular;
    font-weight: bold;
    font-size: 20px;
  }
  .containerKomandaStati .KomandaStati .line1 .card .subtitle {
    font-family: RFD-Light;
    padding: 0 20px;
    font-size: 14px;
    font-weight: bold;
  }
  .containerKomandaStati .KomandaStati .slider .card .subtitle {
    font-family: RFD-Light;
    padding: 0 20px;
    font-size: 14px;
    font-weight: bold;
  }
  .containerKomandaStati .KomandaStati .line1 .card .text .subtitle {
    margin-top: 20px;
    font-family: RFD-Bold;
    font-size: 14px;
  }
  .containerKomandaStati .KomandaStati .slider .card .text .subtitle {
    margin-top: 20px;
    font-family: RFD-Bold;
    font-size: 14px;
  }

  .containerKomandaStati .KomandaStati .line2 {
    width: 970px;
    padding-left: 215px;
  }
  .containerKomandaStati .KomandaStati .line2 .card {
    width: 303px;
    height: 494px;
    float: left;
    margin: 0 10px;
    text-align: left;
    background: #ffffff;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 30px;
  }
  .containerKomandaStati .KomandaStati .line2 .card .img {
    width: 303px;
    height: 404px;
  }
  .containerKomandaStati .KomandaStati .line2 .card .img img {
    width: 303px;
    height: 404px;
  }
  .containerKomandaStati .KomandaStati .line2 .card .title {
    padding: 15px 20px 10px 20px;
    font-family: RFD-Regular;

    font-weight: bold;
    font-size: 20px;
  }
  .containerKomandaStati .KomandaStati .line2 .card .subtitle {
    font-family: RFD-Light;

    font-weight: bold;
    padding: 0 20px;
    font-size: 14px;
  }
  .containerKomandaStati .KomandaStati .line2 .card .text .subtitle {
    margin-top: 20px;
    font-family: RFD-Bold;
    font-size: 14px;
  }
  .containerKomandaKvadratNiz {
    position: relative;
    margin-top: 50px;
    z-index: -1;
  }
  .containerKomandaKvadratNiz .komandakvadratniz {
    position: relative;
    margin: 0 auto;
    width: 1440px;
    height: 500px;
  }
  .containerKomandaKvadratNiz .komandakvadratniz .img1 {
    position: absolute;
    top: 120px;
    right: 81px;
    z-index: -1;
  }
  .containerKomandaKvadratNiz .komandakvadratniz .img1 img {
    width: 300px;
  }
  .containerKomandaKvadratNiz .komandakvadratniz .img2 {
    position: absolute;
    /* top: 129px; */
    right: 50px;
  }
  .containerKomandaKvadratNiz .komandakvadratniz .img2 img {
    width: 50px;
  }
  .hImg {
    position: relative;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
    z-index: 1 !important;
  }
  .hImg1 {
    position: relative;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
    z-index: 1 !important;
  }
  .hImg2 {
    position: relative;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
    z-index: 1 !important;
  }
  .hImg3 {
    position: relative;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.2));
    border-radius: 5px;
    z-index: 1 !important;
  }
  .cImg {
    filter: drop-shadow(0px 0px 50px rgba(255, 75, 0, 0.7));
  }
  .cImg1 {
    filter: drop-shadow(0px 0px 50px rgba(255, 75, 0, 0.7));
  }
  .cImg2 {
    filter: drop-shadow(0px 0px 50px rgba(255, 75, 0, 0.7));
  }
  .cImg3 {
    filter: drop-shadow(0px 0px 50px rgba(255, 75, 0, 0.7));
  }
  .kom1 {
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.25));
  }
  .kom11 {
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.25));
  }
  .kom111 {
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.25));
  }
  .kom2 {
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.25));
  }
  .kom22 {
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.25));
  }
  .kom222 {
    filter: drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.25));
  }
}
</style>

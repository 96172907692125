<template>
  <div class="hide">
    Перейти к декстопной версии...
    <a href="https://zaryaputino.ru">ZaryaPutino.ru</a>
  </div>
  <div id="app">
    <component v-if="!Visibility" class="show" :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>
<script>
import MainLayout from "./layouts/MainLayout";
import SecondLayout from "./layouts/SecondLayout";
import ThirdLayout from "./layouts/ThirdLayout";
import VacanciesLayout from "./layouts/VacanciesLayout";
import StudentsLayout from "./layouts/StudentsLayout";
import PartnersLayout from "./layouts/PartnersLayout";
import ContactsLayout from "./layouts/ContactsLayout";
import NewsLayout from "./layouts/NewsLayout";
export default {
  data() {
    return {
      anim: false,
      Visibility: false,
      width: 0,
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "main") + "-layout";
    },
  },
  mounted() {
    // setTimeout(() => {
    //   this.anim = false;
    // }, 200);
    this.width = window.innerWidth;
    if (this.width > 480) {
      this.Visibility = true;
    }
  },
  components: {
    MainLayout,
    SecondLayout,
    ThirdLayout,
    VacanciesLayout,
    StudentsLayout,
    PartnersLayout,
    ContactsLayout,
    NewsLayout,
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: "RFD-Regular";
  src: url("assets/fonts/RFDewiExpanded-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "RFD-Light";
  src: url("assets/fonts/RFDewiExpanded-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "RFD-Bold";
  src: url("assets/fonts/RFDewiExpanded-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
@media only screen and (max-width: 479px) {
  .bounce-enter-from {
    opacity: 0;
  }
  .bounce-enter-to {
    opacity: 1;
  }
  .bounce-enter-active {
    transition: opacity 1s ease-in-out;
  }
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  .hamburger .hamburger {
    width: 30px;
    right: 25px;
    top: 45px;
    position: absolute;
  }
}
@media only screen and (min-width: 480px) {
  .show {
    display: none;
  }
  .hide {
    display: block;
    position: absolute;
    width: 100%;
    top: 40%;
    text-align: center;
  }
}
</style>

<template>
  <div class="container">
    <div class="maingbg">
      <a href="/"> <div class="logo"></div></a>
      <div class="hamburger">
        <a href="#" @click.prevent="isOpenM">
          <img class="hamburger" src="../assets/hamburgermenu.png" alt=""
        /></a>
      </div>
      <div class="menu" v-if="isOpen">
        <a href="#" @click.prevent="isOpenM">
          <img class="cruze" src="../assets/cruze.png" alt=""
        /></a>
        <img class="logomenu" src="../assets/logomenu.png" alt="" />
        <ul>
          <li>
            <router-link to="/history" exact active-class="active"
              >ИСТОРИЯ</router-link
            >
          </li>
          <li>
            <router-link to="/activity" active-class="active"
              >ДЕЯТЕЛЬНОСТЬ</router-link
            >
          </li>
          <li>
            <router-link to="/vacancies" active-class="active"
              >ВАКАНСИИ</router-link
            >
          </li>
          <li>
            <router-link to="/students" active-class="active"
              >СТУДЕНТАМ</router-link
            >
          </li>
          <li>
            <router-link to="/partners" active-class="active"
              >ПАРТНЕРАМ</router-link
            >
          </li>
          <li>
            <router-link to="/contacts" active-class="active"
              >КОНТАКТЫ</router-link
            >
          </li>
          <li>
            <router-link to="/news" active-class="active">НОВОСТИ</router-link>
          </li>
        </ul>

        <div class="socialblack">
          <a href="https://vk.com/zaryaputino" target="_blank" rel="vk"
            ><img class="left" src="../assets/vkblack.png" alt="" width="30"
          /></a>
        </div>
      </div>
      <div class="title">
        <div class="maintitle">КОНТАКТЫ</div>
      </div>
    </div>
  </div>
  <div class="containerSvyazSNami">
    <div class="title">СВЯЖИТЕСЬ С НАМИ</div>
    <div class="icon">
      <img src="../assets/contacts/maps.png" alt="" class="maps" />
      <img src="../assets/contacts/carta.png" alt="" class="carta" />
      <img src="../assets/contacts/phone.png" alt="" class="phone" />
    </div>
    <div class="spisok">
      <ul>
        <li v-for="SvyazSNamiLi in SvyazSNamiList" :key="SvyazSNamiLi">
          {{ SvyazSNamiLi }}
        </li>
        <!-- <li>
          Пермский край, Верещагинский район, село Путино, ул. Комсомольская, 12
        </li>
        <li>Info@zaryaputino.ru</li>
        <li>+7 (342) 542-33-42</li> -->
      </ul>
    </div>
  </div>
  <div class="containerSvyazSNami2">
    <div class="title" style="text-align: center">
      Также вы можете связаться напрямую с подразделениями:
    </div>
    <div class="cards">
      <div class="uno">
        <img src="../assets/contacts/card.png" alt="" class="card" />
        <div class="title" v-html="SvyazSNami2List[0]"></div>
        <div class="subtitle" v-html="SvyazSNami2List[1]"></div>
      </div>
      <div class="dos">
        <img src="../assets/contacts/card.png" alt="" class="card" />
        <div class="title" v-html="SvyazSNami2List[2]"></div>
        <div class="subtitle" v-html="SvyazSNami2List[3]"></div>
      </div>
      <div class="tres">
        <img src="../assets/contacts/card.png" alt="" class="card" />
        <div class="title" v-html="SvyazSNami2List[4]"></div>
        <div class="subtitle" v-html="SvyazSNami2List[5]"></div>
      </div>
      <div class="quatro">
        <img src="../assets/contacts/card.png" alt="" class="card" />
        <div class="title" v-html="SvyazSNami2List[6]"></div>
        <div class="subtitle" v-html="SvyazSNami2List[7]"></div>
      </div>
      <div class="cinco">
        <img src="../assets/contacts/card.png" alt="" class="card" />
        <div class="title" v-html="SvyazSNami2List[8]"></div>
        <div class="subtitle" v-html="SvyazSNami2List[9]"></div>
      </div>
      <div class="seis">
        <img src="../assets/contacts/card.png" alt="" class="card" />
        <div class="title" v-html="SvyazSNami2List[10]"></div>
        <div class="subtitle" v-html="SvyazSNami2List[11]"></div>
      </div>
    </div>
  </div>
  <div class="containerkarta">
    <img src="../assets/contacts/redromb.png" alt="" class="redromb" />
    <iframe
      src="https://www.google.com/maps/d/embed?mid=1b8v-C6pMs9PIbA4UXn76aFw0vAO4_MiK"
      width="336"
      height="169"
      style="position: absolute; right: 15px; top: 10px"
    ></iframe>
  </div>
  <footer-site></footer-site>
</template>
<script>
import MenuTop from "../components/MenuTop.vue";
import FooterSite from "../components/Footer.vue";
export default {
  data() {
    return {
      isOpen: false,
      SvyazSNamiList: [],
      SvyazSNami2List: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetch("https://cb96744.tmweb.ru/api/articles/");
      const finalRes = await res.json();
      this.SvyazSNamiList = finalRes[38].text.split(";");
      this.SvyazSNami2List = finalRes[39].text.split(";");
    },
    isOpenM() {
      if (this.isOpen == true) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
    },
  },
  components: {
    MenuTop,
    FooterSite,
  },
};
</script>
<style scoped>
.container {
  position: relative;
  height: 100%;
}

.container .maingbg {
  margin: 0 auto;
  background: url("../assets/contacts/contactsbg.png");
  max-width: 414px;
  width: 100%;
  height: 386px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.container .maingbg .logo {
  background-image: url("../assets/logo.png");
  position: relative;
  width: 197px;
  height: 37px;
  left: 90px;
  top: 47px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.openmenu {
  visibility: visible;
  display: block;
  position: absolute;
  width: 375px;
  height: 583px;
  top: 0px;
  background-color: white;
}
.cruze {
  position: absolute;
  top: 17px;
  right: 17px;
  width: 16.67px;
  z-index: 100;
}
.logomenu {
  position: absolute;
  top: 40px;
  width: 272px;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.menu {
  display: block;
  position: absolute;
  max-width: 414px;
  z-index: 100;
  width: 100%;
  height: 583px;
  top: 0px;
  background-color: white;
}
.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  top: 142px;
}
.menu li {
  /* float: left; */
  font-family: RFD-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 17px; */
}
.menu li a {
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.menu li a:hover {
  color: #ff4b00;
}
.clickGeneral {
  position: absolute;
  top: -115px;
  left: 5px;
  width: 200px;
  height: 40px;
}
.menu .router-link-exact-active {
  color: #ff4b00;
}
.container .maingbg .socialblack {
  position: absolute;
  left: 155px;
  top: 450px;
  width: 90px;
  z-index: 101;
  height: 32px;
}
.container .maingbg .socialblack .left {
  float: left;
  position: absolute;
  bottom: 2px;
}
.container .maingbg .socialblack .right {
  float: right;
  position: absolute;
  bottom: 0px;
  left: 62px;
}
.container .maingbg .title {
  display: block;
  position: relative;
  left: 31px;
  top: 240px;
  width: 332px;
  font-family: RFD-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 17px;
}
.container .maingbg .title .maintitle {
  font-size: 30px;
  line-height: 84px;
  color: #ffffff;
}
.container .maingbg .title .secondtitle {
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
}
.containerSvyazSNami {
  position: relative;
  width: 375px;
  margin: 0 auto;
  height: 350px;
  margin-top: 50px;
}
.containerkarta {
  position: relative;
  width: 375px;
  margin: 0 auto;
  height: 220px;
  /* margin-top: 50px; */
}
.containerSvyazSNami .title {
  width: 229px;
  left: 73px;
  position: relative;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
}
.containerSvyazSNami .icon {
  width: 40px;
  float: left;
}
.containerSvyazSNami .icon .maps {
  width: 31px;
  margin: 45px 0px 0px 15px;
}
.containerSvyazSNami .icon .carta {
  width: 40px;
  margin: 45px 0px 0px 15px;
}
.containerSvyazSNami .icon .phone {
  width: 43px;
  margin: 25px 0px 0px 15px;
}
.containerSvyazSNami .spisok {
  /* float: left; */
  width: 277px;
  font-family: RFD-Regular;
  font-weight: bold;
  line-height: 130%;
  font-size: 14px;
  /* line-height: 26px; */
  margin-left: 85px;
}
.containerSvyazSNami .spisok ul li {
  list-style: none;
  margin: 40px 0;
}
.containerSvyazSNami2 {
  position: relative;
  width: 375px;
  margin: 0 auto;
  height: 1200px;
}
.containerSvyazSNami2 .title {
  width: 233px;
  position: relative;
  text-align: left;
  left: 66px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
}
.containerSvyazSNami2 .cards {
  margin-top: 20px;
}
.containerSvyazSNami2 .cards .uno {
  width: 345px;

  float: left;
}

.containerSvyazSNami2 .cards .dos {
  width: 345px;

  float: left;
}

.containerSvyazSNami2 .cards .tres {
  width: 345px;

  float: left;
}

.containerSvyazSNami2 .cards .quatro {
  width: 345px;

  float: left;
}

.containerSvyazSNami2 .cards .cinco {
  width: 345px;

  float: left;
}
.containerSvyazSNami2 .cards .seis {
  width: 345px;

  float: left;
}
.containerSvyazSNami2 .cards .uno {
  position: relative;
  margin: 15px 15px;
}
.containerSvyazSNami2 .cards .uno .card {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 345px;
}
.containerSvyazSNami2 .cards .uno .title {
  position: absolute;
  top: 30px;
  left: 40px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
  color: #ff4b00;
}
.containerSvyazSNami2 .cards .uno .subtitle {
  position: absolute;
  bottom: 30px;
  left: 40px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
  width: 287px;
}
.containerSvyazSNami2 .cards .dos {
  position: relative;
  margin: 15px 15px;
}
.containerSvyazSNami2 .cards .dos .card {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 345px;
}
.containerSvyazSNami2 .cards .dos .title {
  position: absolute;
  top: 30px;
  left: 40px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
  width: 277px;
  color: #ff4b00;
}
.containerSvyazSNami2 .cards .dos .subtitle {
  position: absolute;
  bottom: 30px;
  left: 40px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
  width: 302px;
}
.containerSvyazSNami2 .cards .tres {
  position: relative;
  margin: 15px 15px;
}
.containerSvyazSNami2 .cards .tres .card {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 345px;
}
.containerSvyazSNami2 .cards .tres .title {
  position: absolute;
  top: 30px;
  left: 40px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
  width: 277px;
  color: #ff4b00;
}
.containerSvyazSNami2 .cards .tres .subtitle {
  position: absolute;
  bottom: 30px;
  left: 40px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
  width: 293px;
}
.containerSvyazSNami2 .cards .quatro {
  position: relative;
  margin: 15px 15px;
}
.containerSvyazSNami2 .cards .quatro .card {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 345px;
}
.containerSvyazSNami2 .cards .quatro .title {
  position: absolute;
  top: 30px;
  left: 40px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
  width: 277px;
  color: #ff4b00;
}
.containerSvyazSNami2 .cards .quatro .subtitle {
  position: absolute;
  bottom: 30px;
  left: 40px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
  width: 303px;
}
.containerSvyazSNami2 .cards .cinco {
  position: relative;
  margin: 15px 15px;
}
.containerSvyazSNami2 .cards .cinco .card {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 345px;
}
.containerSvyazSNami2 .cards .cinco .title {
  position: absolute;
  top: 30px;
  left: 40px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
  width: 277px;
  color: #ff4b00;
}
.containerSvyazSNami2 .cards .cinco .subtitle {
  position: absolute;
  bottom: 30px;
  left: 40px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
  width: 297px;
}
.containerSvyazSNami2 .cards .seis {
  position: relative;
  margin: 15px 15px;
}
.containerSvyazSNami2 .cards .seis .card {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 345px;
}
.containerSvyazSNami2 .cards .seis .title {
  position: absolute;
  top: 30px;
  left: 40px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
  color: #ff4b00;
  width: 306px;
}
.containerSvyazSNami2 .cards .seis .subtitle {
  position: absolute;
  bottom: 30px;
  left: 40px;
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
  width: 200px;
}
.containerkarta .redromb {
  position: absolute;
  z-index: -1;
  /* top: -50px; */
  left: 15px;
  width: 327px;
}
</style>

<template>
  <div class="container">
    <div class="maingbg">
      <a href="/"> <div class="logo"></div></a>
      <div class="hamburger">
        <a href="#" @click.prevent="isOpenM">
          <img class="hamburger" src="../assets/hamburgermenu.png" alt=""
        /></a>
      </div>
      <div class="menu" v-if="isOpen">
        <a href="#" @click.prevent="isOpenM">
          <img class="cruze" src="../assets/cruze.png" alt=""
        /></a>
        <img class="logomenu" src="../assets/logomenu.png" alt="" />
        <ul>
          <li>
            <router-link to="/history" exact active-class="active"
              >ИСТОРИЯ</router-link
            >
          </li>
          <li>
            <router-link to="/activity" active-class="active"
              >ДЕЯТЕЛЬНОСТЬ</router-link
            >
          </li>
          <li>
            <router-link to="/vacancies" active-class="active"
              >ВАКАНСИИ</router-link
            >
          </li>
          <li>
            <router-link to="/students" active-class="active"
              >СТУДЕНТАМ</router-link
            >
          </li>
          <li>
            <router-link to="/partners" active-class="active"
              >ПАРТНЕРАМ</router-link
            >
          </li>
          <li>
            <router-link to="/contacts" active-class="active"
              >КОНТАКТЫ</router-link
            >
          </li>
          <li>
            <router-link to="/news" active-class="active">НОВОСТИ</router-link>
          </li>
        </ul>

        <div class="socialblack">
          <a href="https://vk.com/zaryaputino" target="_blank" rel="vk"
            ><img class="left" src="../assets/vkblack.png" alt="" width="30"
          /></a>
        </div>
      </div>
      <div class="title">
        <div class="maintitle">ДЕЯТЕЛЬНОСТЬ</div>
      </div>
    </div>
  </div>
  <!-- Не раскрыт -->
  <div
    v-if="collapseuno == false"
    class="containercollapseuno"
    @click.prevent="collapseuno = !collapseuno"
    :class="{ display: collapseuno }"
  >
    <div class="uno">
      <p>Животноводство</p>
      <img class="img1" src="../assets/activity/arrow.png" alt="" />
      <img class="img2" src="../assets/activity/cow.png" alt="" />
    </div>
  </div>
  <!-- Раскрыт -->
  <div
    v-else-if="collapseuno == true"
    class="containercollapseuno"
    @click.prevent="collapseuno = !collapseuno"
    :class="{ display: !collapseuno }"
  >
    <div class="uno">
      <p>Животноводство</p>
      <img class="img1" src="../assets/activity/arrowdown.png" alt="" />
      <img class="img2" src="../assets/activity/cow.png" alt="" />
    </div>
  </div>
  <div
    v-if="collapseuno == true"
    class="containercollapseunotitle"
    :class="{ display: !collapseuno }"
  >
    <hr />
    <div class="title">Разведение и продажа крупнорогатого молочного скота</div>
    <img src="../assets/activity/redromb.png" alt="" class="redromb" />
    <img src="../assets/activity/img1.png" alt="" class="img1" />
    <img src="../assets/activity/img2.png" alt="" class="img2" />
    <img src="../assets/activity/img3.png" alt="" class="img3" />
    <img src="../assets/activity/img4.png" alt="" class="img4" />
    <img src="../assets/activity/blackromb.png" alt="" class="blackromb" />
    <img src="../assets/activity/cowicon.png" alt="" class="cowicon" />
    <div class="text1" v-html="unoText1"></div>
    <div class="text2" v-html="unoText2"></div>
    <div class="text3" v-html="unoText3"></div>
  </div>
  <div class="containerline">
    <hr />
  </div>
  <!-- Не раскрыт 2 -->
  <div
    v-if="collapsedos == false"
    class="containercollapsedos"
    @click.prevent="collapsedos = !collapsedos"
    :class="{ display: collapsedos }"
  >
    <div class="uno">
      <p>Производство сырого молока</p>
      <img class="img1" src="../assets/activity/arrow.png" alt="" />
      <img class="img2" src="../assets/activity/bottle.png" alt="" />
    </div>
  </div>
  <!-- Раскрыт 2 -->
  <div
    v-else-if="collapsedos == true"
    class="containercollapsedos"
    @click.prevent="collapsedos = !collapsedos"
    :class="{ display: !collapsedos }"
  >
    <div class="uno">
      <p>Производство сырого молока</p>
      <img class="img1" src="../assets/activity/arrowdown.png" alt="" />
      <img class="img2" src="../assets/activity/bottle.png" alt="" />
    </div>
  </div>
  <div
    v-if="collapsedos == true"
    class="containercollapsedostitle"
    :class="{ display: !collapsedos }"
  >
    <hr />
    <div class="title">Производство молочного сырья</div>
    <img src="../assets/activity/redromb2.png" alt="" class="redromb" />
    <img src="../assets/activity/img21.png" alt="" class="img1" />
    <img src="../assets/activity/img22.png" alt="" class="img2" />
    <img src="../assets/activity/img23.png" alt="" class="img3" />
    <img src="../assets/activity/blackromb2.png" alt="" class="blackromb" />
    <div class="text1" v-html="dosText1"></div>
  </div>
  <div class="containerline">
    <hr />
  </div>
  <!-- Не раскрыт 3 -->
  <div
    v-if="collapsetres == false"
    class="containercollapsetres"
    @click.prevent="collapsetres = !collapsetres"
    :class="{ display: collapsetres }"
  >
    <div class="uno">
      <p>Растениеводство</p>
      <img class="img1" src="../assets/activity/arrow.png" alt="" />
      <img class="img2" src="../assets/activity/grains.png" alt="" />
    </div>
  </div>
  <!-- Раскрыт 3 -->
  <div
    v-else-if="collapsetres == true"
    class="containercollapsetres"
    @click.prevent="collapsetres = !collapsetres"
    :class="{ display: !collapsetres }"
  >
    <div class="uno">
      <p>Растениеводство</p>
      <img class="img1" src="../assets/activity/arrowdown.png" alt="" />
      <img class="img2" src="../assets/activity/grains.png" alt="" />
    </div>
  </div>
  <div
    v-if="collapsetres == true"
    class="containercollapsetrestitle"
    :class="{ display: !collapsetres }"
  >
    <hr />
    <div class="title">Растениеводство</div>
    <img src="../assets/activity/redromb3.png" alt="" class="redromb" />
    <img src="../assets/activity/img31.png" alt="" class="img1" />
    <img src="../assets/activity/img32.png" alt="" class="img2" />
    <img src="../assets/activity/img33.png" alt="" class="img3" />
    <img src="../assets/activity/img34.png" alt="" class="img4" />
    <img src="../assets/activity/blackromb3.png" alt="" class="blackromb" />
    <div class="text1" v-html="tresText1"></div>
    <div class="text2" v-html="tresText2"></div>
  </div>
  <!-- Maps -->
  <div class="containermapstitle">КАРТА НАШИХ ОБЪЕКТОВ</div>
  <div class="containermaps">
    <img src="../assets/activity/maps.png" alt="" />
  </div>
  <footer-site></footer-site>
</template>
<script>
import MenuTop from "../components/MenuTop.vue";
import FooterSite from "../components/Footer.vue";
export default {
  data() {
    return {
      isOpen: false,
      collapseuno: false,
      collapsedos: false,
      collapsetres: false,
      pZuzaikaA: "none",
      rZavodA: "none",
      kZavodA: "none",
      zhCompleksA: "none",
      zhCompleks2A: "none",
      zhCompleks3A: "none",
      zCompleksA: "none",
      prMolokaA: "none",
      prMoloka2A: "none",
      garageA: "none",
      generalOficinaA: "none",
      unoText1: "",
      unoText2: "",
      unoText3: "",
      dosText1: "",
      tresText1: "",
      tresText2: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await fetch("https://cb96744.tmweb.ru/api/articles/");
      const finalRes = await res.json();
      this.unoText1 = finalRes[16].text;
      this.unoText2 = finalRes[17].text;
      this.unoText3 = finalRes[18].text;
      this.dosText1 = finalRes[19].text;
      this.tresText1 = finalRes[20].text;
      this.tresText2 = finalRes[21].text;
    },
    isOpenM() {
      if (this.isOpen == true) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
    },
  },
  components: {
    MenuTop,
    FooterSite,
  },
};
</script>

<style scoped>
.container {
  position: relative;
  height: 100%;
}

.container .maingbg {
  margin: 0 auto;
  background: url("../assets/activity/bgactivity.png");
  max-width: 414px;
  width: 100%;
  height: 386px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.container .maingbg .logo {
  background-image: url("../assets/logo.png");
  position: relative;
  width: 197px;
  height: 37px;
  left: 90px;
  top: 47px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.openmenu {
  visibility: visible;
  display: block;
  position: absolute;
  width: 375px;
  height: 583px;
  top: 0px;
  background-color: white;
}
.cruze {
  position: absolute;
  top: 17px;
  right: 17px;
  width: 16.67px;
  z-index: 100;
}
.logomenu {
  position: absolute;
  top: 40px;
  width: 272px;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.menu {
  display: block;
  position: absolute;
  max-width: 414px;
  z-index: 100;
  width: 100%;
  height: 583px;
  top: 0px;
  background-color: white;
}
.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  top: 142px;
}
.menu li {
  /* float: left; */
  font-family: RFD-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  /* line-height: 17px; */
}
.menu li a {
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.menu li a:hover {
  color: #ff4b00;
}
.clickGeneral {
  position: absolute;
  top: -115px;
  left: 5px;
  width: 200px;
  height: 40px;
}
.menu .router-link-exact-active {
  color: #ff4b00;
}
.container .maingbg .socialblack {
  position: absolute;
  left: 155px;
  top: 450px;
  width: 90px;
  z-index: 101;
  height: 32px;
}
.container .maingbg .socialblack .left {
  float: left;
  position: absolute;
  bottom: 2px;
}
.container .maingbg .socialblack .right {
  float: right;
  position: absolute;
  bottom: 0px;
  left: 62px;
}
.container .maingbg .title {
  display: block;
  position: relative;
  left: 30px;
  top: 240px;
  width: 332px;
  font-family: RFD-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 17px;
}
.container .maingbg .title .maintitle {
  font-size: 30px;
  line-height: 84px;
  color: #ffffff;
}
.container .maingbg .title .secondtitle {
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
}
.containercollapseuno {
  position: relative;
  width: 375px;
  margin: 0 auto;
  height: 90px;
  margin-top: 50px;
}
.display {
  display: none;
}
.containercollapseuno .uno {
  display: flex;
  align-items: center;
  margin: 0 15px;
  margin-top: 20px;
}
.containercollapseuno .uno p {
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 18px;
  width: 204px;
}
.containercollapseuno .uno .img1 {
  width: 67px;
  margin-top: 10px;
  height: 67px;
  margin-left: 10px;
}
.containercollapseuno .uno .img2 {
  width: 47px;
  /* margin-top: 10px; */
  height: 28.5px;
  position: absolute;
  right: 15px;
}
.containerline {
  position: relative;
  width: 375px;
  margin: 0 auto;
  height: 0px;
}
.containercollapsedos {
  position: relative;
  width: 375px;
  margin: 0 auto;
  height: 90px;
}
.containercollapsedos .uno {
  display: flex;
  align-items: center;
  margin: 0 15px;
  margin-top: 10px;
}
.containercollapsedos .uno p {
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 18px;
  width: 187px;
}
.containercollapsedos .uno .img1 {
  width: 67px;
  margin-top: 10px;
  height: 67px;
  margin-left: 30px;
}
.containercollapsedos .uno .img2 {
  width: 41px;
  margin-top: 10px;
  height: 41px;
  position: absolute;
  right: 15px;
}
.containercollapsetres {
  position: relative;
  width: 375px;
  margin: 0 auto;
  height: 90px;
}
.containercollapsetres .uno {
  display: flex;
  align-items: center;
  margin: 0 15px;
  margin-top: 10px;
}
.containercollapsetres .uno p {
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 18px;
  width: 207px;
}
.containercollapsetres .uno .img1 {
  width: 67px;
  margin-top: 10px;
  height: 67px;
  margin-left: 10px;
}
.containercollapsetres .uno .img2 {
  width: 40px;
  margin-top: 10px;
  height: 40px;
  position: absolute;
  right: 15px;
}
.containermapstitle {
  position: relative;
  margin: 50px auto;
  width: 375px;
  text-align: center;
  font-family: "RFD-Regular";
  font-weight: bold;
  font-size: 26px;
  /* margin-top: 0px; */
}
.containermaps {
  position: relative;
  margin: 0 auto;
  width: 375px;
}
.containermaps img {
  width: 100%;
}
.containermaps p {
  font-family: RFD-Regular;
  font-size: 14px;
  border-radius: 20px;
  background-color: #ffffff;
  height: 62px;
  width: 244px;
  text-align: center;
  display: grid;
  align-items: center;
}
.containermaps .pZuzaika {
  position: absolute;
  right: 15px;
  top: 70px;
}
.containermaps .pZuzaikaz {
  position: absolute;
  width: 150px;
  height: 150px;
  right: 15px;
  top: 70px;
}

.containermaps .rZavod {
  position: absolute;
  right: 333px;
  top: 243px;
}
.containermaps .rZavodz {
  position: absolute;
  width: 150px;
  height: 150px;
  right: 333px;
  top: 243px;
}
.containermaps .kZavod {
  position: absolute;
  right: 486px;
  top: 146px;
}
.containermaps .kZavodz {
  position: absolute;
  width: 150px;
  height: 150px;
  right: 486px;
  top: 146px;
}
.containermaps .zhCompleks {
  position: absolute;
  right: 478px;
  top: 340px;
}
.containermaps .zhCompleksz {
  position: absolute;
  width: 150px;
  height: 150px;
  right: 478px;
  top: 340px;
}
.containermaps .zhCompleks2 {
  position: absolute;
  left: 160px;
  top: 498px;
}
.containermaps .zhCompleks2z {
  position: absolute;
  width: 150px;
  height: 150px;
  left: 160px;
  top: 498px;
}
.containermaps .zhCompleks3 {
  position: absolute;
  left: 493px;
  top: 1147px;
}
.containermaps .zhCompleks3z {
  position: absolute;
  width: 150px;
  height: 150px;
  left: 493px;
  top: 1147px;
}
.containermaps .zCompleks {
  position: absolute;
  right: 723px;
  top: 210px;
}
.containermaps .zCompleksz {
  position: absolute;
  width: 150px;
  height: 150px;
  right: 723px;
  top: 210px;
}
.containermaps .prMoloka {
  position: absolute;
  right: 620px;
  top: 400px;
}
.containermaps .prMolokaz {
  position: absolute;
  width: 150px;
  height: 150px;
  right: 620px;
  top: 400px;
}
.containermaps .prMoloka2 {
  position: absolute;
  left: 10px;
  top: 536px;
}
.containermaps .prMoloka2z {
  position: absolute;
  width: 150px;
  height: 150px;
  left: 10px;
  top: 536px;
}
.containermaps .garage {
  position: absolute;
  left: 200px;
  top: 659px;
}
.containermaps .garagez {
  position: absolute;
  width: 150px;
  height: 150px;
  left: 200px;
  top: 659px;
}
.containermaps .generalOficina {
  position: absolute;
  left: 16px;
  top: 671px;
}
.containermaps .generalOficinaz {
  position: absolute;
  width: 150px;
  height: 150px;
  left: 16px;
  top: 671px;
}
.containercollapseunotitle {
  position: relative;
  width: 375px;
  margin: 0px auto;
  height: 1377px;
  background-color: #f0f0f0;
}
.containercollapseunotitle .title {
  font-family: RFD-Regular;
  font-weight: bold;
  line-height: 130%;
  font-size: 16px;
  text-align: center;
  width: 263px;
  margin: 20px auto;
}
.containercollapseunotitle .redromb {
  width: 254px;
  height: 86px;
  position: absolute;
  top: 113px;
  left: 52px;
}
.containercollapseunotitle .img1 {
  width: 280px;
  position: absolute;
  top: 125px;
  left: 15px;
  border-radius: 5px;
}
.containercollapseunotitle .img2 {
  width: 273px;
  position: absolute;
  top: 485px;
  right: 15px;
  border-radius: 5px;
}
.containercollapseunotitle .img3 {
  width: 273px;
  position: absolute;
  top: 690px;
  left: 15px;
  border-radius: 5px;
}
.containercollapseunotitle .img4 {
  width: 272px;
  position: absolute;
  top: 1035px;
  right: 15px;
  z-index: 1;
  border-radius: 5px;
}
.containercollapseunotitle .blackromb {
  width: 249px;
  position: absolute;
  top: 1160px;
  height: 70px;
  right: 49px;
}
.containercollapseunotitle .cowicon {
  width: 70px;
  position: absolute;
  top: 1270px;
  left: 15px;
}
.containercollapseunotitle .text1 {
  width: 345px;
  position: absolute;
  top: 340px;
  left: 15px;
  font-family: RFD-Light;
  line-height: 130%;
  font-size: 14px;
}

.containercollapseunotitle .text1 b {
  font-family: RFD-Bold;
}
.containercollapseunotitle .text2 {
  width: 345px;
  position: absolute;
  top: 900px;
  left: 15px;
  font-family: RFD-Light;
  line-height: 130%;
  font-size: 14px;
}
.containercollapseunotitle .text2 b {
  font-family: RFD-Bold;
}
.containercollapseunotitle .text3 {
  width: 255px;
  position: absolute;
  top: 1270px;
  right: 17px;
  font-family: RFD-Light;
  line-height: 130%;
  font-size: 14px;
}
.containercollapsedostitle {
  position: relative;
  width: 375px;
  margin: 0px auto;
  height: 1110px;
  background-color: #f0f0f0;
}
.containercollapsedostitle .title {
  font-family: RFD-Regular;
  font-weight: bold;
  line-height: 130%;
  font-size: 16px;
  text-align: center;
  width: 213px;
  margin: 20px auto;
}
.containercollapsedostitle .redromb {
  width: 214px;
  height: 172px;
  position: absolute;
  top: 795px;
  left: 86px;
  z-index: 0;
}
.containercollapsedostitle .img1 {
  width: 257px;
  position: absolute;
  top: 905px;
  right: 15px;
  border-radius: 5px;
}
.containercollapsedostitle .img2 {
  width: 345px;
  position: absolute;
  top: 90px;
  left: 15px;
  z-index: 2;
  border-radius: 5px;
}
.containercollapsedostitle .img3 {
  width: 257px;
  position: absolute;
  top: 710px;
  left: 15px;
  border-radius: 5px;
}

.containercollapsedostitle .blackromb {
  width: 375px;
  height: 466px;
  position: absolute;
  top: 215px;
  right: 0px;
}
.containercollapsedostitle .text1 {
  width: 345px;
  position: absolute;
  top: 340px;
  left: 15px;
  font-family: RFD-Light;
  font-weight: normal;
  line-height: 130%;
  font-size: 14px;
  color: #ffffff;
}
.containercollapsedostitle .text1 b {
  font-family: RFD-Bold;
}
.containercollapsetrestitle {
  position: relative;
  width: 375px;
  margin: 0px auto;
  height: 1400px;
  background-color: #f0f0f0;
}
.containercollapsetrestitle .title {
  font-family: RFD-Regular;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  width: 184px;
  margin: 20px auto;
}
.containercollapsetrestitle .redromb {
  width: 213px;
  height: 146px;
  position: absolute;
  top: 790px;
  left: 71px;
  z-index: 1;
}
.containercollapsetrestitle .img1 {
  width: 345px;
  position: absolute;
  top: 69px;
  left: 15px;
  z-index: 1;
  border-radius: 5px;
}
.containercollapsetrestitle .img2 {
  width: 227px;
  position: absolute;
  top: 675px;
  right: 15px;
  z-index: 1;
  border-radius: 5px;
}
.containercollapsetrestitle .img3 {
  width: 243px;
  position: absolute;
  top: 840px;
  left: 15px;
  z-index: 1;
  border-radius: 5px;
}
.containercollapsetrestitle .img4 {
  width: 255px;
  position: absolute;
  top: 490px;
  left: 15px;
  border-radius: 5px;
  z-index: 1;
}
.containercollapsetrestitle .blackromb {
  width: 201px;
  height: 135px;
  position: absolute;
  top: 580px;
  right: 68px;
  z-index: 0;
}
.containercollapsetrestitle .text1 {
  width: 345px;
  position: absolute;
  top: 325px;
  left: 15px;
  font-family: RFD-Light;
  line-height: 130%;
  font-size: 14px;
}
.containercollapsetrestitle .text2 {
  width: 345px;
  position: absolute;
  top: 1030px;
  left: 15px;
  font-family: RFD-Light;
  line-height: 130%;
  font-size: 14px;
}
.containercollapsetrestitle .text2 span {
  font-family: "RFD-BOLD";
}
</style>
